export default function authHeader() {
  let user = localStorage.getItem("token");

  if (user) {
    return {
      Authorization: "Bearer " + user,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": localStorage.getItem("lang"),
    };
  } else {
    return {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": localStorage.getItem("lang"),
    };
  }
}
