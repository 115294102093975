<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-white">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div class="max-w-2xl lg:mx-auto text-center">
        <h2
          class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl"
        >
          {{ $t("message.faq") }}
        </h2>
        <p class="mt-4 text-gray-500">{{ $t("message.faqText") }}</p>
      </div>
      <div class="faq-tab border-b border-gray-200">
        <div class="faq-div">
          <div class="tab-content">
            <button
              class="faq-title"
              @click="
                showBookkeeping = false;
                showCompany = true;
                showPayment = false;
                showAdvisor = false;
              "
              :class="[
                showCompany
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                ' py-4 px-1 text-center border-b-2 font-medium text-m',
              ]"
            >
              {{ $t("message.companyFoundText") }}
            </button>
          </div>
          <div class="tab-content">
            <button
              class="faq-title"
              @click="
                showBookkeeping = false;
                showCompany = false;
                showPayment = false;
                showAdvisor = true;
              "
              :class="[
                showAdvisor
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                ' py-4 px-1 text-center border-b-2 font-medium text-m',
              ]"
            >
              {{ $t("message.advisor") }}
            </button>
          </div>
        </div>
        <div class="faq-div">
          <div class="tab-content">
            <button
              class="faq-title"
              @click="
                showBookkeeping = true;
                showCompany = false;
                showPayment = false;
                showAdvisor = false;
              "
              :class="[
                showBookkeeping
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                ' py-4 px-1 text-center border-b-2 font-medium text-m',
              ]"
            >
              {{ $t("message.bookkeepingText") }}
            </button>
          </div>
          <div class="tab-content">
            <button
              class="faq-title"
              @click="
                showBookkeeping = false;
                showCompany = false;
                showPayment = true;
                showAdvisor = false;
              "
              :class="[
                showPayment
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                ' py-4 px-1 text-center border-b-2 font-medium text-m',
              ]"
            >
              {{ $t("message.subscriptionAndPayment") }}
            </button>
          </div>
        </div>
      </div>
      <div class="flex">
        <div>
          <company-faq-tabs v-show="showCompany" />
        </div>
        <div>
          <advisor-faq-tabs v-show="showAdvisor" />
        </div>
        <div>
          <bookkeeping v-show="showBookkeeping" />
        </div>
        <div>
          <subscription-payment-faq-tabs v-show="showPayment" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyFaqTabs from "./CompanyFaqTabs";
import AdvisorFaqTabs from "./AdvisorFaqTabs";
import Bookkeeping from "./BookkeepingFaqTabs";
import SubscriptionPaymentFaqTabs from "./SubscriptionPaymentFaqTabs";

export default {
  components: {
    SubscriptionPaymentFaqTabs,
    Bookkeeping,
    AdvisorFaqTabs,
    CompanyFaqTabs,
  },
  data() {
    return {
      showCompany: true,
      showAdvisor: false,
      showBookkeeping: false,
      showPayment: false,
    };
  },
};
</script>

<style scoped>
.tab-content {
  margin-top: 20px;
}
@media only screen and (max-width: 768px) {
  .faq-tab {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-content: start;
    align-items: start;
  }
  .faq-div {
    display: flex;
  }
}
.faq-tab {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.faq-div {
  display: flex;
}
.faq-title {
  padding: 0;
  margin-right: 20px;
}
</style>
