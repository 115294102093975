import axios from "axios";
import authHeader from "./auth-header";

class PaymentService {
  addPayment(card, productId) {
    return axios
      .post(
        `brands/${localStorage.getItem(
          "type"
        )}/products/${productId}/subscription`,
        {
          card_holder_name: card.name,
          card_number: card.cardNumber,
          card_expire_month: card.card_expire_month,
          card_expire_year: card.card_expire_year,
          card_cvc: card.card_cvc,
          account_id: JSON.parse(localStorage.getItem("account")).id,
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  addInfo(id, user_id, step_name) {
    return axios
      .post(
        `accounts/${id}/informations`,
        {
          informations: [
            {
              key: "step",
              value: step_name,
            },
          ],
        },
        user_id
          ? {
              headers: authHeader(),
              params: { user_id: user_id },
            }
          : { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  addPaymentAdditionalService(card, productId) {
    return axios
      .post(
        `brands/${localStorage.getItem("type")}/products/${productId}/buy`,
        {
          card_holder_name: card.name,
          card_number: card.cardNumber,
          card_expire_month: card.expiration.substring(0, 2),
          card_expire_year: card.expiration.substring(3, 5),
          card_cvc: card.cvc,
          account_id: JSON.parse(localStorage.getItem("account")).id,
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
  addOfficeInfo(id, user_id, step_name) {
    return axios
      .post(
        `accounts/${id}/informations`,
        {
          informations: [
            {
              key: "office_step",
              value: step_name,
            },
          ],
        },
        user_id
          ? {
              headers: authHeader(),
              params: { user_id: user_id },
            }
          : { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
}

export default PaymentService;
