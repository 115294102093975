import axios from "axios";
import authHeader from "./auth-header";

class ProductsService {
  getProducts(id, params, filters) {
    let brand = localStorage.getItem("type")
      ? localStorage.getItem("type")
      : "1";
    return axios
      .get(
        `brands/${brand}/products${id ? `/${id}` : ""}${
          filters ? `?${filters}` : ""
        }`,
        params !== ""
          ? {
              headers: authHeader(),
              params: { search: params },
            }
          : { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
  getBrandPackets(brand, params) {
    return axios
      .get(`brands/${brand}/products`, { params, headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
  getCategories() {
    return axios
      .get(`categories`, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
  getPackets(params) {
    return axios
      .get(`brands/2/products`, { params, headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
  getVirtualOffices(id, params) {
    return axios
      .get(`brands/2/products${id ? `/${id}` : ""}`, {
        headers: authHeader(),
        params: { filters: params },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
  addPacketInfo(packet, id, user_id, step_name) {
    return axios
      .post(
        `accounts/${id}/informations`,
        {
          informations: [
            {
              key: "packet",
              value: packet.name,
            },
            {
              key: "packet_id",
              value: packet.id,
            },
            {
              key: "step",
              value: step_name,
            },
          ],
        },
        user_id
          ? {
              headers: authHeader(),
              params: { user_id: user_id },
            }
          : { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
  addOfficePacketInfo(packet, user_id, step_name) {
    return axios
      .post(
        `accounts/${
          JSON.parse(localStorage.getItem("account")).id
        }/informations`,
        {
          informations: [
            {
              key: "office_packet",
              value: packet.name,
            },
            {
              key: "office_packet_id",
              value: packet.id,
            },
            {
              key: "office_step",
              value: step_name,
            },
          ],
        },
        user_id
          ? {
              headers: authHeader(),
              params: { user_id: user_id },
            }
          : { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }
  removeProduct(account_id, product_id) {
    return axios
      .delete(`accounts/${account_id}/products/${product_id}/remove`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
}

export default ProductsService;
