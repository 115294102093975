import axios from "axios";
import authHeader from "./auth-header";

class AuthService {
  registerUser(user) {
    return axios
      .post(
        "auth/register",
        {
          language_id: 1,
          name: user.name,
          phone: "+90" + user.phone,
          email: user.email,
          password: user.password,
          password_confirmation: user.password,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  loginUser(user) {
    return axios
      .post(
        "auth/login",
        {
          email: user.email,
          password: user.password,
          remember_me: user.remember_me,
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  resetPassword(user) {
    return axios
      .post(
        "auth/reset-password",
        {
          email: user.email,
          password: user.password,
          token: user.token,
          password_confirmation: user.password,
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  forgotPassword(email) {
    return axios
      .post(
        "auth/forgot-password",
        {
          email: email,
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  logout() {
    return axios
      .post("auth/logout", {}, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
}

export default AuthService;
