import axios from "axios";
import authHeader from "./auth-header";

class UserService {
  getUsers() {
    return axios
      .get(`users`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
  getUserInfo(id, user_id, filter) {
    return axios
      .get(
        `users${id ? `/${id}` : ""}${filter ? `?${filter}` : ""}`,
        user_id
          ? {
              headers: authHeader(),
              params: { user_id: user_id },
            }
          : { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  editInfo(user, id) {
    return axios
      .put(
        `users/${id}`,
        {
          name: user.name,
          email: user.email,
          phone: "+90" + user.phone.replace(/\s/g, ""),
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  editAvatar(file) {
    return axios
      .post(
        `users/${JSON.parse(localStorage.getItem("user")).id}/update-avatar`,
        file,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  emailVerify(id, hash, signature, expires, email) {
    const params = {
      signature: signature,
      expires: expires,
      email: email,
    };
    return axios
      .get(`verify/email/${id}/${hash}`, { headers: authHeader(), params })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  sendVerifyMail() {
    return axios
      .get(`verify/email/send-email-verification-notification`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  refreshToken() {
    return axios
      .get(`auth/refresh-token`, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  deleteToken() {
    return axios
      .get(`auth/tokens/delete`, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
  deleteUser(id) {
    return axios
      .delete(`/users/${id}`, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  verifyPhoneNumber(code) {
    return axios
      .get(`verify/sms?verification_code=${code}`, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  sendVerifyNotificationPhoneNumber() {
    return axios
      .get(`verify/sms/send-sms-verification-notification`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  changePassword(pass, pass_confirm) {
    return axios
      .post(
        `users/${JSON.parse(localStorage.getItem("user")).id}/change-password`,
        {
          password: pass,
          password_confirmation: pass_confirm,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  addInfo(information, id, user_id) {
    return axios
      .post(
        `accounts/${id}/informations`,
        {
          informations: [
            {
              key: "identityNo",
              value: information.identityNo,
            },
            {
              key: "birthCity",
              value: information.birthCity,
            },
            {
              key: "birthDist",
              value: information.birthDist,
            },
            {
              key: "birthDate",
              value: information.birthDate,
            },
            {
              key: "city",
              value: information.city,
            },
            {
              key: "district",
              value: information.district,
            },
            {
              key: "phone",
              value: information.phone,
            },
            {
              key: "street",
              value: information.street,
            },
            {
              key: "neighbourhood",
              value: information.neighbourhood,
            },
            {
              key: "buildingNo",
              value: information.buildingNo,
            },
            {
              key: "doorNo",
              value: information.doorNo,
            },
            {
              key: "address_option",
              value: information.address_option,
            },
            {
              key: "rental_amount_brut",
              value: information.rental_amount_brut,
            },
            {
              key: "rental_period",
              value: information.rental_period,
            },
            {
              key: "rental_amount_net",
              value: information.rental_amount_net,
            },
            {
              key: "currency",
              value: information.currency,
            },
            {
              key: "virtual_office_selection",
              value: information.virtual_office_selection,
            },
            {
              key: "virtual_office_id",
              value:
                information.virtual_office_id !== "-"
                  ? information.virtual_office_id.id
                  : "-",
            },
          ],
        },
        user_id
          ? {
              headers: authHeader(),
              params: { user_id: user_id },
            }
          : { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }
  addUser(accountInfo, user_id) {
    return axios
      .post(
        `users`,
        {
          name: accountInfo.name,
          email: accountInfo.email,
          password: accountInfo.password,
          phone: accountInfo.phone,
          role_id: accountInfo.role.id,
        },
        user_id
          ? {
              headers: authHeader(),
              params: { user_id: user_id },
            }
          : { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }
}

export default UserService;
