<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="relative bg-gray-50 overflow-hidden">
    <div
      class="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full"
      aria-hidden="true"
    >
      <div class="relative h-full max-w-7xl mx-auto">
        <svg
          class="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
        >
          <defs>
            <pattern
              id="f210dbf6-a58d-4871-961e-36d5016a0f49"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                class="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="404"
            height="784"
            fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
          />
        </svg>
        <svg
          class="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
        >
          <defs>
            <pattern
              id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                class="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="404"
            height="784"
            fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)"
          />
        </svg>
      </div>
    </div>
    <div class="relative pt-6 pb-6 bg-gray-800">
      <Popover>
        <div class="max-w-[1380px] mx-auto px-4 sm:px-6 xl:px-24">
          <nav
            style="z-index: 9999999"
            class="relative mobile-nav flex items-center justify-between sm:h-10 md:justify-center"
            aria-label="Global"
          >
            <div
              class="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0"
            >
              <div class="flex items-center justify-between w-full md:w-auto">
                <a class="hidden xl:flex" href="#">
                  <span class="sr-only">Emusavirim</span>
                  <img
                    class="h-10"
                    src="../../public/img/e-musavirim.png"
                    alt="Workflow"
                  />
                </a>
                <div class="-mr-2 flex items-center xl:hidden">
                  <PopoverButton
                    class="bg-gray-600 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  >
                    <span class="sr-only">Open main menu</span>
                    <MenuIcon class="h-6 w-6" aria-hidden="true" />
                  </PopoverButton>
                </div>
              </div>
            </div>
            <div class="hidden xl:flex xl:space-x-4 lg:space-x-2">
              <a
                href="#howIsWork"
                class="font-medium text-white hover:text-gray-300"
                >{{ $t("message.howIsWork") }}</a
              >
              <a
                href="#advantages"
                class="font-medium text-white hover:text-gray-300"
                >{{ $t("message.adventages") }}</a
              >
              <a
                href="#services"
                class="font-medium text-white hover:text-gray-300"
                >{{ $t("message.services") }}</a
              >
              <a
                href="#packets"
                class="font-medium text-white hover:text-gray-300"
                >{{ $t("message.packets") }}</a
              >
              <a
                href="#faq"
                class="font-medium text-white hover:text-gray-300"
                >{{ $t("message.faq") }}</a
              >
              <a
                href="#contact"
                class="font-medium text-white hover:text-gray-300"
                >{{ $t("message.contact") }}</a
              >
            </div>
            <div
              class="hidden xl:absolute xl:flex xl:items-center xl:justify-end xl:inset-y-0 xl:right-0 gap-2 pr-10"
            >
              <span class="inline-flex rounded-md shadow">
                <a
                  :href="`${$store.state.lang}/app/login`"
                  class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 gap-2"
                >
                  {{ $t("message.myAccount") }}
                </a>
              </span>
              <span class="inline-flex rounded-md shadow">
                <a
                  target="_blank"
                  :href="`https://calendly.com/emusavirim/toplanti-olusturun`"
                  class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 gap-2"
                >
                  {{ $t("message.setADemo") }}
                </a>
              </span>
            </div>
          </nav>
          <Listbox
            style="z-index: 9999999"
            as="div"
            class="absolute mobile-lang right-0 top-4"
            v-model="$i18n.locale"
          >
            <div class="relative">
              <ListboxButton
                class="relative w-1/2 bg-transparent flex items-center space-x-2 text-white px-3 py-2 rounded-md text-sm font-medium"
              >
                <span class="block truncate pr-12">
                  <img
                    style="max-width: unset"
                    class="h-10 w-10"
                    :src="`/img/${$i18n.locale}.png`"
                    alt="add"
                  />
                </span>
              </ListboxButton>

              <transition
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <ListboxOptions
                  class="company-drop-list absolute z-30 mt-1 w-1/2 bg-white shadow-lg max-h-60 rounded-md text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                >
                  <ListboxOption
                    as="template"
                    v-for="locale in $i18n.availableLocales"
                    :key="`locale-${locale}`"
                    :value="locale"
                    v-slot="{ active }"
                  >
                    <li
                      :class="[
                        active ? 'text-white bg-indigo-600' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-4 pr-4',
                      ]"
                    >
                      <span class="block truncate">
                        <img width="30" :src="`/img/${locale}.png`" alt="add" />
                      </span>
                    </li>
                  </ListboxOption>
                </ListboxOptions>
              </transition>
            </div>
          </Listbox>
        </div>
        <transition
          enter-active-class="duration-150 ease-out"
          enter-from-class="opacity-0 scale-95"
          enter-to-class="opacity-100 scale-100"
          leave-active-class="duration-100 ease-in"
          leave-from-class="opacity-100 scale-100"
          leave-to-class="opacity-0 scale-95"
        >
          <PopoverPanel
            focus
            class="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div
              class="rounded-lg shadow-md bg-gray-700 ring-1 ring-black ring-opacity-5 overflow-hidden"
            >
              <div class="px-5 pt-4 flex items-center justify-between">
                <div></div>
                <div class="-mr-2">
                  <PopoverButton
                    class="bg-gray-600 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  >
                    <span class="sr-only">Close menu</span>
                    <XIcon class="h-6 w-6" aria-hidden="true" />
                  </PopoverButton>
                </div>
              </div>
              <div class="px-2 pt-2 pb-3">
                <a
                  v-for="item in navigation"
                  :key="item.name"
                  :href="item.href"
                  class="block px-3 py-2 rounded-md text-base font-medium text-gray-100 hover:text-gray-500 hover:bg-gray-50"
                  >{{ item.name }}</a
                >
              </div>
              <a
                :href="`/${this.$store.state.lang}/app/login`"
                class="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-100 hover:bg-gray-200"
              >
                {{ $t("message.login") }}
              </a>
            </div>
          </PopoverPanel>
        </transition>
      </Popover>
    </div>

    <div
      class="relative pt-6 pb-16 sm:pb-24 custom-bottom bg-gray-800 bg-banner"
    >
      <div class="bg-opacity-div"></div>
      <main
        class="relative mt-16 mx-auto max-w-4xl px-4 sm:mt-24 header-pd-custom z-50"
      >
        <div class="text-center">
          <h1
            class="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl"
          >
            <span class="block xl:inline text-white">
              {{ $t("message.newGenerationComp") }}</span
            >
            {{ " " }}
            <span class="block text-white xl:inline">{{
              $t("message.manageComp")
            }}</span>
          </h1>
          <div class="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
            <div class="rounded-md shadow">
              <a
                href="#packets"
                class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-700 md:py-4 md:text-lg md:px-10 btn-shadow"
              >
                {{ $t("message.shallWeStart") }}
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
  <div id="howIsWork">
    <Howiswork />
  </div>
  <div id="advantages">
    <Advantages />
  </div>
  <div id="services">
    <Services />
  </div>
  <div id="packets">
    <PacketsContent />
  </div>
  <div id="faq">
    <Faq />
  </div>
  <div>
    <Testimonials />
  </div>
  <div id="contact">
    <Contact />
  </div>
  <div>
    <Footer />
  </div>
</template>

<script>
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Popover,
  PopoverButton,
  PopoverPanel,
} from "@headlessui/vue";
import { MenuIcon, XIcon } from "@heroicons/vue/outline";
import Howiswork from "@/components/website/Howiswork";
import Advantages from "@/components/website/Advantages";
import Services from "@/components/website/Services";
import PacketsContent from "@/components/website/PacketsContent";
import Faq from "@/components/website/faq/Faq";
import Testimonials from "@/components/website/Testimonials";
import Contact from "@/components/website/Contact";
import Footer from "@/components/website/Footer";

const navigation = [
  { name: "Nasıl Çalışıyoruz?", href: "#howIsWork" },
  { name: "Avantajlarımız", href: "#advantages" },
  { name: "Hizmetlerimiz", href: "#services" },
  { name: "Paketlerimiz", href: "#packets" },
  { name: "SSS", href: "#faq" },
  { name: "İletişim", href: "#contact" },
];

export default {
  components: {
    Footer,
    Contact,
    Testimonials,
    Faq,
    PacketsContent,
    Services,
    Advantages,
    Howiswork,
    Popover,
    PopoverButton,
    PopoverPanel,
    MenuIcon,
    XIcon,
    Listbox,
    ListboxOption,
    ListboxButton,
    ListboxOptions,
  },
  setup() {
    return {
      navigation,
    };
  },
  data() {
    return {
      isUserScrolling: "",
    };
  },
  computed: {
    watchLang() {
      return this.$i18n.locale;
    },
  },
  watch: {
    watchLang(val) {
      localStorage.setItem("lang", val);
      this.$store.state.lang = val;
    },
  },
  async created() {
    this.$i18n.locale = this.$route.params.lang;
  },
};
</script>

<style lang="scss" scoped>
html {
  scroll-behavior: smooth;
}
.custom-bottom {
  padding-bottom: 11rem !important;
}
.header-pd-custom {
  margin-top: 10rem !important;
}
.price-txt-size {
  font-size: 3rem !important;
}
.sticker-size {
  font-size: 0.675rem !important;
}
.small-tx {
  font-size: 0.9rem !important;
}
.started-price-info {
  font-size: 13px;
  line-height: 19px;
  color: #6d6d6d;
  font-weight: 300;
}
.title {
  font-weight: 600;
}
.price {
  font-weight: 800;
  font-size: 27px;
  margin-top: 18px;
  line-height: 10px;
}
.text-line {
  font-weight: 300 !important;
  font-size: 0.8rem !important;
}
.custom-pd {
  padding-bottom: 0px !important;
  padding-top: 10px !important;
}
.pd-top-6 {
  padding-top: 6rem !important;
}
.pd-bottom-0 {
  padding-bottom: 0px !important;
}
.packed-list-item {
  margin-top: 9px !important;
  &:first-child {
    margin-top: 0px;
  }
}
.tab-content {
  margin-top: 20px;
}
.customer-title {
  margin-bottom: 80px;
}
.mobile-detail {
  display: none !important;
}
.bg-banner {
  background: url("/img/bgemusavirim_1680.jpg");
}
.bg-opacity-div {
  width: 100%;
  top: 0px;
  height: 100%;
  position: absolute;
  background: #6366f29e;
  z-index: 2;
}
.btn-shadow {
  box-shadow: 0px 0px 16px #ffff;
}
.btn-shadow:hover {
  box-shadow: 0px 0px 16px 10px #ffff;
}
@media only screen and (max-width: 1024px) {
  .mobile-nav {
    position: absolute;
    right: 4rem;
  }
  .mobile-lang {
    right: 0.375rem;
  }
}
@media only screen and (max-width: 500px) {
  .mobile-nav {
    right: 2rem;
  }
}
</style>
