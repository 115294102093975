<template>
  <div>
    <div class="mt-1 relative rounded-md shadow-sm">
      <input
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        type="text"
        v-on:keypress="cardExpirationDateValidations($event)"
        v-maska="'##/##'"
      />
    </div>
  </div>
</template>

<script>
import { maska } from "maska";
export default {
  name: "CardExpirationDateInput",
  components: {},
  props: {
    modelValue: String,
  },
  directives: {
    maska,
  },
  data() {
    return {
      date: "",
    };
  },
  methods: {
    cardExpirationDateValidations(e) {
      if (!e.key.match(/^\d+$/)) {
        e.preventDefault();
      }
    },
  },
};
</script>

<style scoped></style>
