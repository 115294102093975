<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="py-12 bg-white">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="lg:text-center">
        <p
          class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl"
        >
          {{ $t("message.ourServices") }}
        </p>
        <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
          {{ $t("message.digitalAsistText") }}
          <br />
          {{ $t("message.newCompanyText") }}
        </p>
      </div>

      <div class="py-12 bg-white">
        <div class="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <h2 class="sr-only">A better way to send money.</h2>
          <dl class="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
            <div>
              <dt>
                <div class="flex items-center justify-center">
                  <div
                    class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white"
                  >
                    <BriefcaseIcon class="h-6 w-6" aria-hidden="true" />
                  </div>
                </div>
                <p
                  class="mt-5 text-center text-lg leading-6 font-medium text-gray-900"
                >
                  {{ $t("message.companyFoundText") }}
                </p>
              </dt>
              <dd class="mt-2 text-center text-base text-gray-500">
                {{ $t("message.companyFoundDesc") }}
              </dd>
            </div>
            <div>
              <dt>
                <div class="flex items-center justify-center">
                  <div
                    class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white"
                  >
                    <OfficeBuildingIcon class="h-6 w-6" aria-hidden="true" />
                  </div>
                </div>
                <p
                  class="mt-5 text-center text-lg leading-6 font-medium text-gray-900"
                >
                  {{ $t("message.officeSolution") }}
                </p>
              </dt>
              <dd class="mt-2 text-center text-base text-gray-500">
                {{ $t("message.officeSolutionDesc") }}
              </dd>
            </div>
            <div>
              <dt>
                <div class="flex items-center justify-center">
                  <div
                    class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white"
                  >
                    <DocumentDuplicateIcon class="h-6 w-6" aria-hidden="true" />
                  </div>
                </div>
                <p
                  class="mt-5 text-center text-lg leading-6 font-medium text-gray-900"
                >
                  {{ $t("message.followDocs") }}
                </p>
              </dt>
              <dd class="mt-2 text-center text-base text-gray-500">
                {{ $t("message.followDocsDesc") }}
              </dd>
            </div>
            <div>
              <dt>
                <div class="flex items-center justify-center">
                  <div
                    class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white"
                  >
                    <ScaleIcon class="h-6 w-6" aria-hidden="true" />
                  </div>
                </div>
                <p
                  class="mt-5 text-center text-lg leading-6 font-medium text-gray-900"
                >
                  {{ $t("message.lawCons") }}
                </p>
              </dt>
              <dd class="mt-2 text-center text-base text-gray-500">
                {{ $t("message.lawConsDesc") }}
              </dd>
            </div>
            <div>
              <dt>
                <div class="flex items-center justify-center">
                  <div
                    class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white"
                  >
                    <CalculatorIcon class="h-6 w-6" aria-hidden="true" />
                  </div>
                </div>
                <p
                  class="mt-5 text-center text-lg leading-6 font-medium text-gray-900"
                >
                  {{ $t("message.bookkeepingText") }}
                </p>
              </dt>
              <dd class="mt-2 text-center text-base text-gray-500">
                {{ $t("message.bookkeepingTextDesc") }}
              </dd>
            </div>
            <div>
              <dt>
                <div class="flex items-center justify-center">
                  <div
                    class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white"
                  >
                    <UserAddIcon class="h-6 w-6" aria-hidden="true" />
                  </div>
                </div>
                <p
                  class="mt-5 text-center text-lg leading-6 font-medium text-gray-900"
                >
                  {{ $t("message.advisor") }}
                </p>
              </dt>
              <dd class="mt-2 text-center text-base text-gray-500">
                {{ $t("message.advisorDesc") }}
              </dd>
            </div>
            <div>
              <dt>
                <div class="flex items-center justify-center">
                  <div
                    class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white"
                  >
                    <AtSymbolIcon class="h-6 w-6" aria-hidden="true" />
                  </div>
                </div>
                <p
                  class="mt-5 text-center text-lg leading-6 font-medium text-gray-900"
                >
                  {{ $t("message.brand") }}
                </p>
              </dt>
              <dd class="mt-2 text-center text-base text-gray-500">
                {{ $t("message.brandDesc") }}
              </dd>
            </div>
            <div>
              <dt>
                <div class="flex items-center justify-center">
                  <div
                    class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white"
                  >
                    <DesktopComputerIcon class="h-6 w-6" aria-hidden="true" />
                  </div>
                </div>
                <p
                  class="mt-5 text-center text-lg leading-6 font-medium text-gray-900"
                >
                  {{ $t("message.digitalBrand") }}
                </p>
              </dt>
              <dd class="mt-2 text-center text-base text-gray-500">
                {{ $t("message.digitalBrandDesc") }}
              </dd>
            </div>
            <div>
              <dt>
                <div class="flex items-center justify-center">
                  <div
                    class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white"
                  >
                    <RefreshIcon class="h-6 w-6" aria-hidden="true" />
                  </div>
                </div>
                <p
                  class="mt-5 text-center text-lg leading-6 font-medium text-gray-900"
                >
                  {{ $t("message.transformation") }}
                </p>
              </dt>
              <dd class="mt-2 text-center text-base text-gray-500">
                {{ $t("message.transformationDesc") }}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BriefcaseIcon,
  OfficeBuildingIcon,
  DocumentDuplicateIcon,
  ScaleIcon,
  CalculatorIcon,
  RefreshIcon,
  DesktopComputerIcon,
  UserAddIcon,
  AtSymbolIcon,
} from "@heroicons/vue/outline";

export default {
  components: {
    BriefcaseIcon,
    OfficeBuildingIcon,
    DocumentDuplicateIcon,
    ScaleIcon,
    CalculatorIcon,
    RefreshIcon,
    DesktopComputerIcon,
    UserAddIcon,
    AtSymbolIcon,
  },
};
</script>
