<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="relative bg-indigo-700 py-16 sm:py-24 lg:py-32">
    <div
      class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl"
    >
      <p
        class="mt-2 text-3xl font-extrabold text-white tracking-tight sm:text-4xl"
      >
        {{ $t("message.advantages") }}
      </p>
      <p class="mt-5 max-w-prose mx-auto text-xl text-white"></p>
      <div class="mt-12">
        <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          <div class="pt-6">
            <div class="h-52 flow-root bg-white rounded-lg px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span
                    class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg"
                  >
                    <CloudUploadIcon
                      class="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <h3
                  class="mt-8 text-lg font-medium text-gray-900 tracking-tight"
                >
                  {{ $t("message.management") }}
                </h3>
                <p class="mt-5 text-base text-gray-500">
                  {{ $t("message.managementDesc") }}
                </p>
              </div>
            </div>
          </div>
          <div class="pt-6">
            <div class="h-52 flow-root bg-white rounded-lg px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span
                    class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg"
                  >
                    <LockClosedIcon
                      class="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <h3
                  class="mt-8 text-lg font-medium text-gray-900 tracking-tight"
                >
                  {{ $t("message.followCompFound") }}
                </h3>
                <p class="mt-5 text-base text-gray-500">
                  {{ $t("message.followCompFoundDesc") }}
                </p>
              </div>
            </div>
          </div>
          <div class="pt-6">
            <div class="h-52 flow-root bg-white rounded-lg px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span
                    class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg"
                  >
                    <RefreshIcon
                      class="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <h3
                  class="mt-8 text-lg font-medium text-gray-900 tracking-tight"
                >
                  {{ $t("message.easyDocsFollow") }}
                </h3>
                <p class="mt-5 text-base text-gray-500">
                  {{ $t("message.easyDocsFollowDesc") }}
                </p>
              </div>
            </div>
          </div>
          <div class="pt-6">
            <div class="h-52 flow-root bg-white rounded-lg px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span
                    class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg"
                  >
                    <ShieldCheckIcon
                      class="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <h3
                  class="mt-8 text-lg font-medium text-gray-900 tracking-tight"
                >
                  {{ $t("message.bookkeepingText") }}
                </h3>
                <p class="mt-5 text-base text-gray-500">
                  {{ $t("message.bookkeepingDesc2") }}
                </p>
              </div>
            </div>
          </div>
          <div class="pt-6">
            <div class="h-52 flow-root bg-white rounded-lg px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span
                    class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg"
                  >
                    <CogIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </span>
                </div>
                <h3
                  class="mt-8 text-lg font-medium text-gray-900 tracking-tight"
                >
                  {{ $t("message.officeSolutions") }}
                </h3>
                <p class="mt-5 text-base text-gray-500">
                  {{ $t("message.officeSolutionsDesc") }}
                </p>
              </div>
            </div>
          </div>
          <div class="pt-6">
            <div class="h-52 flow-root bg-white rounded-lg px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span
                    class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg"
                  >
                    <ServerIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </span>
                </div>
                <h3
                  class="mt-8 text-lg font-medium text-gray-900 tracking-tight"
                >
                  {{ $t("message.additionalServiceAdvantages") }}
                </h3>
                <p class="mt-5 text-base text-gray-500">
                  {{ $t("message.additionalServiceAdvantagesDesc") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  CloudUploadIcon,
  CogIcon,
  LockClosedIcon,
  RefreshIcon,
  ServerIcon,
  ShieldCheckIcon,
} from "@heroicons/vue/outline";

export default {
  components: {
    CloudUploadIcon,
    CogIcon,
    LockClosedIcon,
    RefreshIcon,
    ServerIcon,
    ShieldCheckIcon,
  },
};
</script>
