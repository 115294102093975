import config from "../config/index.js";
import axios from "axios";
import store from "../store";

export default {
  handleGeneralErrors(error) {
    store.dispatch("hideLoading");
    store.dispatch("showNotification", {
      title: "Error",
      description: error.response.data.message || error.message,
      type: "error",
    });
  },
  unauthorizedErrorHandler(error) {
    localStorage.clear();
    this.handleGeneralErrors(error);
  },
  tooManyRequestsErrorHandler(error) {
    setTimeout(() => {
      window.location.reload();
    }, 1000 * 20);
    this.handleGeneralErrors(error);
  },
  beforeSendingRequest(conf) {
    store.dispatch("showLoading");
    conf.headers["Accept-Language"] = localStorage.getItem("lang");
    conf.headers["Accept"] = "application/json";
    // const user = $cookies.get("user");
    // if (user) {
    //   conf.headers["Authorization"] = "Bearer " + user.token;
    // }
    return conf;
  },
  handleErrors(error) {
    const code = error.response ? error.response.status : error.code;
    switch (code) {
      case 401:
        this.unauthorizedErrorHandler(error);
        break;
      case 429:
        this.tooManyRequestsErrorHandler(error);
        break;
      default:
        this.handleGeneralErrors(error);
        break;
    }

    return Promise.reject(error);
  },
  onResponseSuccess(response) {
    store.dispatch("hideLoading");
    if (response.config.method !== "get" && response.data.message) {
      store.dispatch("showNotification", {
        title: "Success",
        description: response.data.message,
        type: "success",
      });
    }
    return response.data;
  },
  install() {
    axios.defaults.baseURL = config.API.BASE_URL;
    axios.interceptors.request.use(this.beforeSendingRequest);
    axios.interceptors.response.use(
      (response) => this.onResponseSuccess(response),
      (error) => this.handleErrors(error)
    );
  },
};
