<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-white">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div>
        <dl
          class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12 lg:grid-cols-3"
        >
          <div>
            <dt class="text-lg leading-6 font-medium text-gray-900">
              {{ $t("message.advisorQuestion1") }}
            </dt>
            <dd class="mt-2 text-base text-gray-500">
              {{ $t("message.advisorAnswer1") }}
            </dd>
          </div>
          <div>
            <dt class="text-lg leading-6 font-medium text-gray-900">
              {{ $t("message.advisorQuestion2") }}
            </dt>
            <dd class="mt-2 text-base text-gray-500">
              {{ $t("message.advisorAnswer2") }}
            </dd>
          </div>
          <div>
            <dt class="text-lg leading-6 font-medium text-gray-900">
              {{ $t("message.advisorQuestion3") }}
            </dt>
            <dd class="mt-2 text-base text-gray-500">
              {{ $t("message.advisorAnswer3") }}
            </dd>
          </div>
          <div>
            <dt class="text-lg leading-6 font-medium text-gray-900">
              {{ $t("message.advisorQuestion4") }}
            </dt>
            <dd class="mt-2 text-base text-gray-500">
              {{ $t("message.advisorAnswer4") }}
            </dd>
          </div>
          <div>
            <dt class="text-lg leading-6 font-medium text-gray-900">
              {{ $t("message.advisorQuestion5") }}
            </dt>
            <dd class="mt-2 text-base text-gray-500">
              {{ $t("message.advisorAnswer5") }}
            </dd>
          </div>
          <div>
            <dt class="text-lg leading-6 font-medium text-gray-900">
              {{ $t("message.advisorQuestion6") }}
            </dt>
            <dd class="mt-2 text-base text-gray-500">
              {{ $t("message.advisorAnswer6") }}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>
