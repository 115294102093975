<template>
  <div
    v-show="loading"
    class="w-full h-full fixed block top-0 left-0 bg-black opacity-50 z-50 flex items-center justify-center"
  >
    <div
      class="w-24 h-24 border-l-2 border-indigo-700 rounded-full animate-spin"
    ></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      loading: "getLoading",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
