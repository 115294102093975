import axios from "axios";
import authHeader from "./auth-header";

class ContactService {
  sendMail(contact, time) {
    return axios
      .post(
        `contact`,
        {
          subject: "Müşteri İletişim",
          message: contact.message + " " + contact.phone,
          full_name: contact.full_name,
          email: contact.email,
          honeypot_time: time,
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  getContact(product_id) {
    return axios.get(
      `brands/${localStorage.getItem(
        "type"
      )}/products/${product_id}/contact?account_id=${
        JSON.parse(localStorage.getItem("account")).id
      }`,
      { headers: authHeader() }
    );
  }
  addInfo(packet, id) {
    return axios
      .post(
        `accounts/${id}/informations`,
        {
          informations: [
            {
              key: "additional_services",
              value: packet,
            },
          ],
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  honeyPot() {
    return axios
      .get(`honey-time`, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
}

export default ContactService;
