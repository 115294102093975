<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-gray-800">
    <div class="pt-12 sm:pt-16 lg:pt-24">
      <div class="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
        <div class="max-w-3xl mx-auto space-y-2 lg:max-w-none">
          <h2
            class="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider"
          >
            {{ $t("message.ourPackets") }}
          </h2>
          <p class="text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl">
            {{ $t("message.priceForYou") }}
          </p>
          <p class="text-xl text-gray-300">
            {{ $t("message.choosePacketFoundComp") }}
          </p>
        </div>
      </div>
    </div>
    <div class="mt-8 pb-12 bg-gray-50 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
      <div class="relative">
        <div class="absolute inset-0 h-3/4 bg-gray-800" />
        <div class="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div
            class="max-w-md mx-auto space-y-4 lg:max-w-7xl lg:grid lg:grid-cols-4 lg:gap-5 lg:space-y-0"
          >
            <div
              v-for="packet in packets"
              :key="packet.name"
              v-show="packet.is_main === 1"
              class="flex flex-col rounded-lg shadow-lg overflow-hidden"
            >
              <div class="px-6 py-8 bg-white sm:p-10 sm:pb-6 padding-btm">
                <div>
                  <h3
                    class="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600 sticker-size"
                    id="tier-standard"
                  >
                    {{ packet.name }}
                  </h3>
                </div>
                <div
                  class="mt-4 flex items-baseline text-6xl font-extrabold price-txt-size"
                >
                  {{ packet.price }} ₺
                  <span
                    class="ml-1 text-2xl font-medium text-gray-500 small-tx"
                  >
                    / {{ $t("message.monthly") }}
                  </span>
                </div>
              </div>
              <div
                class="flex-1 flex flex-col justify-between bg-white px-6 pt-6 pb-8 space-y-6 sm:p-10 sm:pt-6"
              >
                <ul role="list" class="space-y-4">
                  <li
                    v-for="feature in packet.features"
                    :key="feature"
                    class="flex items-start"
                  >
                    <div class="flex-shrink-0">
                      <CheckIcon
                        class="h-6 w-6 text-green-500"
                        aria-hidden="true"
                      />
                    </div>
                    <p class="ml-3 text-base text-gray-700">
                      {{ feature.name }}
                    </p>
                  </li>
                </ul>
              </div>
              <div
                class="flex-1 flex flex-col justify-between bg-gray-50 px-6 pt-6 pb-8 space-y-6 sm:p-10 sm:pt-6"
              >
                <div
                  class="ml-3 text-base text-gray-700"
                  v-for="child in packet.childrens"
                  :key="child.name"
                >
                  <div class="title">{{ child.name }}</div>
                  <div class="price">
                    {{ child.price }} ₺
                    <span
                      class="ml-1 text-2xl font-medium text-gray-500 small-tx"
                    >
                      + KDV
                    </span>
                    <div class="text-line text-gray-500">
                      {{ $t("message.forOnce") }}
                    </div>
                  </div>
                </div>
                <div class="rounded-md shadow">
                  <a
                    :href="`${$store.state.lang}/app/register`"
                    class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900"
                    aria-describedby="tier-standard"
                  >
                    {{ $t("message.subscribe") }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="items-center pt-16 flex gap-8 justify-center">
        <h3
          class="text-center text-gray-900 text-lg font-large truncate advisor-name"
        >
          {{ $t("message.PacketVideoModalText") }}
        </h3>

        <button
          @click="addVideoModal = true"
          type="text"
          name="city"
          class="focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-indigo-300 bg-indigo-500 hover:bg-indigo-700 rounded-md text-white"
        >
          {{ $t("message.videoPlayer") }}
        </button>
      </div>
      <TransitionRoot as="template" :show="addVideoModal">
        <Dialog as="div" class="relative z-10" @close="addVideoModal = false">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            />
          </TransitionChild>

          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div
              class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
            >
              <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enter-to="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leave-from="opacity-100 translate-y-0 sm:scale-100"
                leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel
                  class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all w-full"
                  style="max-width: 800px; height: 490px"
                >
                  <div class="">
                    <button
                      type="button"
                      class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      @click="addVideoModal = false"
                    >
                      <span class="sr-only">Close</span>
                      <XIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div class="sm:flex sm:items-start w-full h-full">
                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <iframe
                        src="https://www.youtube.com/embed/TSEMXjuWLmw"
                        frameborder="0"
                        allowfullscreen
                        class="absolute top-0 left-0 w-full h-full"
                      ></iframe>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
    </div>
  </div>
</template>

<script>
import { CheckIcon, XIcon } from "@heroicons/vue/outline";
import ProductsService from "../../services/products.service";
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

const tiers = [
  {
    name: "Şahıs Şirketi",
    href: "/app/application-form",
    priceMonthly: 49,
    description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit.",
    features: ["Ön Muhasebe", "Mali Müşavir", "200 Fatura Kontör"],
  },
  {
    name: "Adi Ortaklık Şirketi",
    href: "/app/application-form",
    priceMonthly: 79,
    description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit.",
    features: ["Ön Muhasebe", "Mali Müşavir", "200 Fatura Kontör"],
  },
  {
    name: "Enterprise",
    href: "/app/application-form",
    priceMonthly: 79,
    description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit.",
    features: [
      "Ön Muhasebe",
      "Mali Müşavir",
      "200 Fatura Kontör",
      "E-Dönüşüm",
      "Hukuk Danışmanlığı",
    ],
  },
  {
    name: "Enterprise",
    href: "/app/application-form",
    priceMonthly: 79,
    description: "Lorem ipsum dolor sit amet consectetur, adipisicing elit.",
    features: [
      "Ön Muhasebe",
      "Mali Müşavir",
      "200 Fatura Kontör",
      "E-Dönüşüm",
      "Hukuk Danışmanlığı",
    ],
  },
];

export default {
  components: {
    CheckIcon,
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  setup() {
    return {
      tiers,
    };
  },
  data() {
    return {
      packets: [],
      addVideoModal: false,
    };
  },
  computed: {
    watchLang() {
      return this.$i18n.locale;
    },
  },
  watch: {
    async watchLang(val) {
      localStorage.setItem("lang", val);
      this.$router.push({ name: this.$route.name, params: { lang: val } });
      await this.getPackets();
    },
  },
  methods: {
    async getPackets() {
      let response = await new ProductsService().getProducts();
      this.packets = response.result;
    },
  },
  async created() {
    await this.getPackets();
  },
};
</script>

<style scoped>
.price-txt-size {
  font-size: 3rem !important;
}
.sticker-size {
  font-size: 0.675rem !important;
}
.small-tx {
  font-size: 0.9rem !important;
}
.started-price-info {
  font-size: 13px;
  line-height: 19px;
  color: #6d6d6d;
  font-weight: 300;
}
.title {
  font-weight: 600;
}
.price {
  font-weight: 800;
  font-size: 27px;
  margin-top: 18px;
  line-height: 10px;
}
.text-line {
  font-weight: 300 !important;
  font-size: 0.8rem !important;
}
.padding-btm {
  padding-bottom: 0px !important;
}
</style>
