import { createApp } from "vue";
import App from "./App.vue";
import "./index.css";
import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import AxiosPlugin from "@/plugins/axios.js";
import vueI18n from "@/plugins/vue-i18n";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import { DatePicker } from "v-calendar";
import PrimeVue from "primevue/config";
import Calendar from "primevue/calendar";

const globalOptions = {
  mode: "auto",
  autocomplete: "off",
};

const app = createApp(App);
app.use(store);
app.use(AxiosPlugin);
app.provide("axios", app.config.globalProperties.axios);
app.use(router);
app.use(DatePicker);
app.use(vueI18n);
app.use(PrimeVue);
app.use(Calendar);
app.use(VueTelInput, globalOptions);
app.component("Calendar", Calendar);

// axios.interceptors.response.use(
//   function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return response;
//   },
//   async function (error) {
//     if (
//       error.response.status === 401 &&
//       window.location.pathname !== `${store.state.lang}/app/login`
//     ) {
//       if (error.response.data.message == "Permission Denied!") {
//         store.dispatch("showNotification", {
//           title: error.response.data.message,
//           description: "Bu işlem için yetkiniz bulunmamaktadır.",
//           type: "error",
//         });
//       }
//     }
//     return Promise.reject(error);
//   }
// );
// axios.defaults.baseURL = process.env.VUE_APP_API_URL;
app.mount("#app");
