import { createStore } from "vuex";
import i18n from "@/plugins/vue-i18n";
// let messages = localStorage.getItem('lang') ? i18n.global.messages[localStorage.getItem('lang')] : i18n.global.messages['tr']
// let language = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'tr'

export default createStore({
  state: {
    me: {},
    addCompanyOnList: {},
    messages: localStorage.getItem("lang")
      ? i18n.global.messages[localStorage.getItem("lang")]
      : i18n.global.messages["tr"],
    officePackets: [],
    steps: [],
    endlessOfficeSteps: [],
    navigation: [],
    endlessOfficeNav: [],
    enbNav: [],
    userPermissions: [],
    metaTitles: [],
    notification: {
      show: false,
      title: "",
      description: "",
      // accepted ['error', 'success', 'info', 'warning']
      type: "success",
    },
    loading: false,
    account: { id: 0, name: "" },
    avatar:
      localStorage.getItem("avatar") !== "https://api.emusavirim.netnull"
        ? localStorage.getItem("avatar")
        : "https://api.emusavirim.net/storage/uploads/1635151409_renkli.png",
    accounts: [],
    is_email_verified: false,
    type: 0,
    lang: localStorage.getItem("lang") ? localStorage.getItem("lang") : "tr",
    user: null,
    lastActiveTime: null,
  },
  mutations: {
    setSteps(state) {
      state.steps = [
        {
          id: "1",
          name: state.messages.message["companySituation"],
          to: `/${state.lang}/app/application-form`,
          status: "current",
          visible: true,
          slug: "status",
        },
        {
          id: "2",
          name: state.messages.message["packets"],
          to: `/${state.lang}/app/application-form/packets`,
          status: "upcoming",
          visible: true,
          slug: "packets",
        },
        {
          id: "3",
          name: state.messages.message["personalInfo"],
          to: `/${state.lang}/app/application-form/personal-info`,
          status: "upcoming",
          visible: true,
          slug: "personal-info",
        },
        {
          id: "4",
          name: state.messages.message["addressInfo"],
          to: `/${state.lang}/app/application-form/business-info`,
          status: "upcoming",
          visible: true,
          slug: "business-info",
        },
        {
          id: "5",
          name: state.messages.message["docsInfo"],
          to: `/${state.lang}/app/application-form/files`,
          status: "upcoming",
          visible: true,
          slug: "files",
        },
        {
          id: "6",
          name: state.messages.message["payment"],
          to: `/${state.lang}/app/application-form/payment`,
          status: "upcoming",
          visible: true,
          slug: "payment",
        },
        {
          id: "7",
          name: state.messages.message["approvement"],
          to: `/${state.lang}/app/application-form/wait-for-approve`,
          status: "upcoming",
          visible: true,
          slug: "wait-for-approve",
        },
      ];
    },
    setEndlessOfficeSteps(state) {
      state.endlessOfficeSteps = [
        {
          id: "1",
          name: state.messages.message["location"],
          to: `/${state.lang}/app/office/application-form`,
          status: "current",
          visible: true,
          slug: "locations",
        },
        {
          id: "2",
          name: state.messages.message["packets"],
          to: `/${state.lang}/app/office/application-form/packets`,
          status: "upcoming",
          visible: true,
          slug: "office-packets",
        },
        {
          id: "3",
          name: state.messages.message["payment"],
          to: `/${state.lang}/app/office/application-form/payment`,
          status: "upcoming",
          visible: true,
          slug: "office-payment",
        },
        {
          id: "4",
          name: state.messages.message["approvement"],
          to: `/${state.lang}/app/office/application-form/wait-for-approve`,
          status: "upcoming",
          visible: true,
          slug: "office-wait-for-approve",
        },
      ];
    },
    setNavigation(state) {
      state.navigation = [
        {
          name: state.messages.message["buyPackageStep"],
          to: `/${state.lang}/app/application-form/packets`,
          current: false,
          permission: "can't buy products",
        },
        {
          name: state.messages.message["docs"],
          to: `/${state.lang}/app/documents`,
          current: false,
          permission: "show files",
        },
        {
          name: state.messages.message["accountant"],
          to: `/${state.lang}/app/accountancy`,
          current: false,
          permission: "show accountancy",
        },
        {
          name: state.messages.message["additionalServices"],
          to: `/${state.lang}/app/additional-services`,
          current: false,
          permission: "show additional services",
        },
        {
          name: state.messages.message["companies"],
          to: `/${state.lang}/app/accounts`,
          current: false,
          permission: "show accounts all",
        },
        {
          name: state.messages.message["advisors"],
          to: `/${state.lang}/app/advisors`,
          current: false,
          permission: "show advisors",
        },
        {
          name: state.messages.message["secretaries"],
          to: `/${state.lang}/app/secretaries`,
          current: false,
          permission: "show secretaries",
        },
        {
          name: state.messages.message["users"],
          to: `/${state.lang}/app/users`,
          current: false,
          permission: "show users all",
        },
        {
          name: state.messages.message["roles"],
          to: `/${state.lang}/app/roles`,
          current: false,
          permission: "show roles",
        },
        {
          name: state.messages.message["appointmentList"],
          to: `/${state.lang}/app/appointment-list`,
          current: false,
          permission: "show appointment requests",
        },
        {
          name: state.messages.message["rolesAndPermissions"],
          to: `/${state.lang}/app/roles-and-permissions`,
          current: false,
          permission: "show permissions",
        },
        {
          name: state.messages.message["announcement"],
          to: `/${state.lang}/app/announcements`,
          current: false,
          permission: "show announcements",
        },
      ];
    },
    setEndlessOfficeNav(state) {
      state.endlessOfficeNav = [
        {
          name: state.messages.message["buyPackageStep"],
          to: `/${state.lang}/app/office/application-form`,
          current: false,
          permission: "can't buy products",
        },
        {
          name: state.messages.message["callInfo"],
          to: `/${state.lang}/app/office/calls`,
          current: false,
          permission: "show calls",
        },
        {
          name: state.messages.message["shipmentTracking"],
          to: `/${state.lang}/app/office/shipment-tracking`,
          current: false,
          permission: "show shipment-tracking",
        },
        // {
        //   name: state.messages.message["additionalServices"],
        //   to: `/${state.lang}/app/office/additional-services`,
        //   current: false,
        // },
        {
          name: state.messages.message["ENB"],
          to: `/${state.lang}/app/endless-network/companies`,
          current: false,
          permission: "show enb companies",
        },
      ];
    },
    setEnbNav(state) {
      state.enbNav = [
        {
          name: state.messages.message["companies"],
          to: `/${state.lang}/app/endless-network/companies`,
          current: false,
          permission: "",
        },
        {
          name: state.messages.message["stage"],
          to: `/${state.lang}/app/endless-network/activity-calendar`,
          current: false,
          permission: "",
        },
      ];
    },
    setLang(state, payload) {
      state.lang =
        payload || localStorage.getItem("lang")
          ? localStorage.getItem("lang")
          : "tr";
    },
    setMessages(state) {
      state.messages = localStorage.getItem("lang")
        ? i18n.global.messages[localStorage.getItem("lang")]
        : i18n.global.messages["tr"];
    },
    setNotification(state, notification) {
      state.notification = notification;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setMetaTitles(state) {
      state.metaTitles = {
        login: state.messages.message["login"],
        docs: state.messages.message["docs"],
        landing: state.messages.message["landing"],
        officeLocation: state.messages.message["officeLocation"],
        officePackets: state.messages.message["officePackets"],
        companySituation: state.messages.message["companySituation"],
        payment: state.messages.message["payment"],
        callInfo: state.messages.message["callInfo"],
        shipmentTracking: state.messages.message["shipmentTracking"],
        announcement: state.messages.message["announcement"],
        additionalServices: state.messages.message["additionalServices"],
        generalInfo: state.messages.message["generalInfo"],
        changePassword: state.messages.message["changePassword"],
        myCompanies: state.messages.message["myCompanies"],
        myInvoices: state.messages.message["myInvoices"],
        myCompany: state.messages.message["myCompany"],
        subscriptions: state.messages.message["subscriptions"],
        employees: state.messages.message["employees"],
        addEmployee: state.messages.message["addEmployee"],
        editEmployee: state.messages.message["editEmployee"],
        stage: state.messages.message["stage"],
        stand: state.messages.message["stand"],
        activityCalendar: state.messages.message["activityCalendar"],
        cookiePolicy: state.messages.message["cookiePolicy"],
        privacyPolicy: state.messages.message["privacyPolicy"],
        userAgreement: state.messages.message["userAgreement"],
        home: state.messages.message["home"],
        register: state.messages.message["register"],
        forgotPassword: state.messages.message["forgotPassword"],
        packets: state.messages.message["packets"],
        accountant: state.messages.message["accountant"],
        companies: state.messages.message["companies"],
        users: state.messages.message["users"],
        advisors: state.messages.message["advisors"],
        secretaries: state.messages.message["secretaries"],
        personalInfo: state.messages.message["personalInfo"],
        profile: state.messages.message["profile"],
        activityArea: state.messages.message["activityArea"],
        files: state.messages.message["files"],
        thanks: state.messages.message["thanks"],
        awaitingApproval: state.messages.message["awaitingApproval"],
        companyFounded: state.messages.message["companyFounded"],
        endlessNetwork: state.messages.message["endlessNetwork"],
        bundlePurchase: state.messages.message["bundlePurchase"],
        cantBuyProducts: state.messages.message["cantBuyProducts"],
        appointmentList: state.messages.message["appointmentList"],
        roles: state.messages.message["roles"],
        rolesAndPermissions: state.messages.message["rolesAndPermissions"],
      };
    },
    login(state, user) {
      state.user = user;
      state.lastActiveTime = new Date().getTime();
    },
    logout(state) {
      state.user = null;
      state.lastActiveTime = null;
    },
    updateLastActiveTime(state) {
      state.lastActiveTime = new Date().getTime();
    },
  },
  actions: {
    async getNavs({ commit }) {
      await commit("setMessages");
      await commit("setLang");
      commit("setSteps");
      commit("setEndlessOfficeSteps");
      commit("setNavigation");
      commit("setEndlessOfficeNav");
      commit("setEnbNav");
      commit("setMetaTitles");
    },
    showNotification(context, notification) {
      context.commit("setNotification", { ...notification, show: true });
      setTimeout(function () {
        context.dispatch("hideNotification").then((r) => {
          return r;
        });
      }, 10000);
    },
    hideNotification(context) {
      context.commit("setNotification", {
        show: false,
        title: "",
        description: "",
        type: "success",
      });
    },
    showLoading(context) {
      context.commit("setLoading", true);
    },
    hideLoading(context) {
      context.commit("setLoading", false);
    },
    checkActivity({ state, commit }) {
      setInterval(() => {
        if (
          state.user &&
          new Date().getTime() - state.lastActiveTime > 7200000
        ) {
          localStorage.clear();
        } else {
          commit("updateLastActiveTime");
        }
      }, 1000);
    },
  },
  modules: {},
  getters: {
    getNotification: (state) => {
      return state.notification;
    },
    getLoading: (state) => {
      return state.loading;
    },
  },
});
