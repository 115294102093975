<template>
  <Disclosure as="nav" class="bg-gray-800" v-slot="{ open }">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between h-16">
        <div class="flex items-center">
          <div class="">
            <a
              :href="
                role_name !== 'customer'
                  ? `/${$store.state.lang}/app/dashboard`
                  : `/${$store.state.lang}/app`
              "
            >
              <img
                class="lg:h-36 h-24 w-full bg-transparent"
                src="../../public/img/renkli.png"
                alt="Workflow"
              />
            </a>
          </div>
          <div class="hidden md:block">
            <div class="nav-step-btn">
              <div
                @click="setProductType('dashboard')"
                :class="[
                  dashboard ? 'active' : '',
                  'menu-btn dashboard px-3 py-2 rounded-md text-sm font-medium',
                ]"
              >
                {{ $t("message.home") }}
              </div>
              <div
                @click="setProductType('emusavirim')"
                :class="[
                  emusavirim ? 'active' : '',
                  'menu-btn emusavirim px-3 py-2 rounded-md text-sm font-medium',
                ]"
              >
                Emüşavirim
              </div>
              <div
                v-show="checkPermission('show calls')"
                @click="setProductType('endlessoffice')"
                :class="[
                  endlessoffice ? 'active' : '',
                  'menu-btn office px-3 py-2 rounded-md text-sm font-medium',
                ]"
              >
                Endless Offices
              </div>
              <!--              <div v-show="role_name !== 'admin' && checkPermission('enb')" @click="setProductType('enb')" :class="[enb ? 'active' : '','menu-btn enb px-3 py-2 rounded-md text-sm font-medium',]">-->
              <!--                ENB-->
              <!--              </div>-->
            </div>
          </div>
        </div>
        <div class="hidden md:block">
          <div class="ml-4 flex items-center md:ml-6">
            <div class="">
              <span class="inline-flex rounded-md shadow">
                <a
                  target="_blank"
                  :href="`https://calendly.com/emusavirim/toplanti-olusturun`"
                  class="font-medium text-white hover:text-gray-300 px-4 py-2"
                >
                  {{ $t("message.setADemo") }}
                </a>
              </span>
            </div>
            <Listbox
              v-show="accounts.length"
              as="div"
              v-model="selectedItem"
              class="firm-select"
            >
              <ListboxLabel
                class="block text-sm font-medium text-gray-700"
              ></ListboxLabel>
              <div
                class="mt-1 relative"
                v-if="storeAccount"
                v-show="checkPermission('show accounts')"
              >
                <!--                  bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium-->
                <ListboxButton
                  class="relative w-full bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                  <span class="block truncate pr-20">{{
                    selectedItem.name
                  }}</span>
                  <span
                    class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                  >
                    <SelectorIcon
                      class="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </ListboxButton>

                <transition
                  leave-active-class="transition ease-in duration-100"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <ListboxOptions
                    class="company-drop-list w-max absolute z-30 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                  >
                    <ListboxOption
                      as="template"
                      v-for="account in accounts"
                      :key="account.id"
                      :value="account"
                      v-slot="{ active, selected }"
                    >
                      <li
                        @click="setAccountId(account)"
                        :class="[
                          active ? 'bg-gray' : 'text-gray-900',
                          'cursor-default select-none relative py-2 pl-8 pr-4',
                        ]"
                      >
                        <span
                          :class="[
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate',
                          ]"
                        >
                          {{ account.name }}
                        </span>

                        <span
                          v-if="selected"
                          :class="[
                            active ? 'text-white' : 'text-indigo-600',
                            'absolute inset-y-0 left-0 flex items-center pl-1.5',
                          ]"
                        >
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>

                    <div
                      v-show="checkPermission('store accounts')"
                      style="
                        position: sticky;
                        bottom: 0;
                        z-index: 99999;
                        background-color: #ffffff;
                      "
                    >
                      <ListboxOption>
                        <button
                          @click="addAccountModal = true"
                          class="new-company-btn w-full flex justify-center py-1 px-3 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          <img src="../../public/img/add.svg" alt="add" />
                          <span>{{ $t("message.newComp") }}</span>
                        </button>
                      </ListboxOption>
                    </div>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>

            <button
              type="button"
              class="display-none info-button bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
            >
              <span class="sr-only">View notifications</span>
              <BellIcon class="h-6 w-6" aria-hidden="true" />
            </button>
            <!-- Profile dropdown -->
            <Listbox as="div" v-model="$i18n.locale">
              <div class="relative mt-1">
                <ListboxButton
                  class="z-50 max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                >
                  <span class="sr-only">Open user menu</span>
                  <img
                    class="h-8 w-8 rounded-full"
                    :src="this.$store.state.avatar"
                    alt=""
                  />
                  <span
                    class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                  >
                    <SelectorIcon
                      class="h-5 w-5 text-gray-400"
                      v-show="false"
                      aria-hidden="true"
                    />
                  </span>
                </ListboxButton>

                <transition
                  leave-active-class="transition ease-in duration-100"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <ListboxOptions
                    class="z-50 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                  >
                    <div class="py-1" role="none">
                      <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                      <a
                        href="#"
                        class="disable text-indigo-700 group flex items-center px-2.5 py-2 text-sm font-bold cursor-default"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        {{ $t("message.generalInfo") }}
                      </a>
                      <router-link
                        @click="showDropdown = !showDropdown"
                        :to="`/${this.$store.state.lang}/app/profile`"
                        class="hover:bg-indigo-200 hover:text-indigo-700 px-4 text-gray-700 group flex items-center px-4 py-2 text-sm gap-1"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-1"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-4 w-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                          />
                        </svg>
                        {{ $t("message.personalInfo") }}
                      </router-link>
                      <router-link
                        @click="showDropdown = !showDropdown"
                        :to="`/${this.$store.state.lang}/app/profile/change-password`"
                        class="hover:bg-indigo-200 hover:text-indigo-700 px-4 text-gray-700 group flex items-center px-4 py-2 text-sm gap-1"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-4"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-4 w-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                          />
                        </svg>
                        {{ $t("message.changePassword") }}
                      </router-link>
                    </div>
                    <div class="py-1" role="none">
                      <a
                        href="#"
                        class="hover:none text-indigo-700 group flex items-center px-2.5 py-2 text-sm font-bold cursor-default"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-2"
                      >
                        {{ $t("message.accountInfo") }}
                      </a>
                      <router-link
                        @click="showDropdown = !showDropdown"
                        :to="`/${this.$store.state.lang}/app/profile/companies`"
                        class="hover:bg-indigo-200 hover:text-indigo-700 px-4 text-gray-700 group flex items-center px-4 py-2 text-sm gap-1"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-5"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-4 w-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                          />
                        </svg>
                        {{ $t("message.companies") }}
                      </router-link>
                      <router-link
                        @click="showDropdown = !showDropdown"
                        :to="`/${this.$store.state.lang}/app/profile/invoices`"
                        class="hover:bg-indigo-200 hover:text-indigo-700 px-4 text-gray-700 group flex items-center px-4 py-2 text-sm gap-1"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-7"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-4 w-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                          />
                        </svg>
                        {{ $t("message.invoices") }}
                      </router-link>
                      <router-link
                        @click="showDropdown = !showDropdown"
                        :to="`/${this.$store.state.lang}/app/profile/subscriptions`"
                        v-show="role_name === 'customer'"
                        class="hover:bg-indigo-200 hover:text-indigo-700 hover:text-white hover:text-white px-4 text-gray-700 group flex items-center px-4 py-2 text-sm w-full"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-3"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-4 w-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
                          />
                        </svg>
                        {{ $t("message.subscriptions") }}
                      </router-link>
                    </div>
                    <div
                      @click="logout"
                      class="py-1 hover:bg-gray-50 hover:shadow-lg"
                      role="none"
                    >
                      <button
                        class="hover:bg-indigo-200 hover:text-indigo-700 text-gray-700 group flex items-center px-4 py-2 text-sm w-full gap-1"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-6"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-4 w-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                          />
                        </svg>
                        {{ $t("message.logout") }}
                      </button>
                    </div>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>

            <!--END Profile dropdown -->

            <Listbox as="div" v-model="$i18n.locale">
              <div class="mt-1 relative">
                <ListboxButton
                  class="relative w-1/2 bg-transparent text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                  <span class="block truncate pr-20">
                    <img
                      width="100"
                      :src="`/img/${$i18n.locale}.png`"
                      alt="add"
                    />
                  </span>
                  <span
                    class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                  >
                    <SelectorIcon
                      class="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </ListboxButton>

                <transition
                  leave-active-class="transition ease-in duration-100"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <ListboxOptions
                    class="company-drop-list absolute z-30 mt-1 w-1/2 bg-white shadow-lg max-h-60 rounded-md text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                  >
                    <ListboxOption
                      as="template"
                      v-for="locale in $i18n.availableLocales"
                      :key="`locale-${locale}`"
                      :value="locale"
                      v-slot="{ active }"
                    >
                      <li
                        :class="[
                          active ? 'text-white bg-indigo-600' : 'text-gray-900',
                          'cursor-default select-none relative py-2 pl-4 pr-4',
                        ]"
                      >
                        <span class="block truncate">
                          <img
                            width="30"
                            :src="`/img/${locale}.png`"
                            alt="add"
                          />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
          </div>
        </div>
        <div class="-mr-2 flex md:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton
            class="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
          >
            <span class="sr-only">Open main menu</span>
            <MenuIcon
              style="z-index: 9999999"
              v-if="!open"
              class="block h-6 w-6"
              aria-hidden="true"
            />
            <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
      </div>
    </div>
    <div
      v-if="is_email_verified === 0"
      class="max-w-7xl bg-yellow-400 md:hidden mx-auto px-4 sm:px-6 lg:px-8"
    >
      <div class="flex justify-center items-center h-20">
        <div class="flex-col py-12 px-2">
          <div
            class="flex justify-between items-middle text-white space-x-4 sm:flex sm:justify-center"
          >
            {{ $t("message.emailApproveText") }}
          </div>
          <div class="flex justify-center">
            <button
              @click="sendVerifyMail"
              class="border border-transparent text-sm font-medium p-1 rounded-md text-white bg-yellow-600"
            >
              {{ $t("message.sendAgain") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <DisclosurePanel class="md:hidden">
      <div class="pt-4 border-t border-gray-700">
        <div class="flex items-center px-5">
          <div class="leading-none mr-3">
            <Menu as="div" class="relative">
              <div>
                <MenuButton
                  class="max-w-xs bg-transparent rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                >
                  <span class="sr-only">Open user menu</span>
                  <img
                    class="h-8 w-8 rounded-full"
                    :src="this.$store.state.avatar"
                    alt=""
                  />
                </MenuButton>
              </div>
            </Menu>
          </div>
          <div>
            <div class="ml-4 flex items-center md:ml-6">
              <Listbox
                v-show="accounts.length"
                as="div"
                v-model="selectedItem"
                class="firm-select"
              >
                <ListboxLabel
                  class="block text-sm font-medium text-gray-700"
                ></ListboxLabel>
                <div
                  class="mt-1 relative"
                  v-show="checkPermission('show accounts')"
                >
                  <!--                  bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium-->
                  <ListboxButton
                    class="relative w-full bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium"
                  >
                    <span class="block truncate pr-20">{{
                      selectedItem.name
                    }}</span>
                    <span
                      class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                    >
                      <SelectorIcon
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition ease-in duration-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions
                      class="company-drop-list absolute z-30 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                    >
                      <ListboxOption
                        as="template"
                        v-for="account in accounts"
                        :key="account.id"
                        :value="account"
                        v-slot="{ active, selected }"
                      >
                        <li
                          @click="setAccountId(account)"
                          :class="[
                            active
                              ? 'text-white bg-indigo-600'
                              : 'text-gray-900',
                            'cursor-default select-none relative py-2 pl-8 pr-4',
                          ]"
                        >
                          <span
                            :class="[
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate',
                            ]"
                            >{{ account.name }}</span
                          >

                          <span
                            v-if="selected"
                            :class="[
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 left-0 flex items-center pl-1.5',
                            ]"
                          >
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>

                      <div
                        v-show="checkPermission('store accounts')"
                        style="
                          position: sticky;
                          bottom: 0;
                          z-index: 99999;
                          background-color: #ffffff;
                        "
                      >
                        <ListboxOption>
                          <button
                            @click="addAccountModal = true"
                            class="new-company-btn w-full flex justify-center py-1 px-3 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            <img src="../../public/img/add.svg" alt="add" />
                            <span>{{ $t("message.newComp") }}</span>
                          </button>
                        </ListboxOption>
                      </div>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>

              <button
                type="button"
                class="display-none info-button bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              >
                <span class="sr-only">View notifications</span>
                <BellIcon class="h-6 w-6" aria-hidden="true" />
              </button>

              <!-- Profile dropdown -->
            </div>
          </div>
        </div>
        <div>
          <div class="flex flex-col flex-grow overflow-y-auto px-2">
            <div class="mt-5 flex-grow flex flex-col">
              <nav class="flex-1 px-2" aria-label="Sidebar">
                <div>
                  <!-- Current: "bg-gray-100 text-gray-900", Default: "bg-gray-900 text-gray-600 hover:bg-gray-50 hover:text-gray-900" -->
                  <button
                    @click="showProfileMenu = !showProfileMenu"
                    type="button"
                    class="bg-gray-800 text-gray-100 group w-full flex items-center pr-2 py-2 text-left text-sm font-medium rounded-md"
                    aria-controls="sub-menu-4"
                    aria-expanded="false"
                  >
                    <!-- Expanded: "text-gray-400 rotate-90", Collapsed: "text-gray-300" -->
                    <svg
                      class="text-gray-300 mr-2 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                    </svg>
                    Profil
                  </button>
                  <!-- Expandable link section, show/hide based on state. -->
                  <div
                    v-show="showProfileMenu"
                    class="space-y-1"
                    id="sub-menu-3"
                  >
                    <div class="submenu-content">
                      <router-link
                        v-for="(item, id) in userNavigation"
                        :key="id"
                        :to="item.to"
                        v-show="item.show"
                        class="block py-2 rounded-md text-base font-medium text-gray-200 hover:text-white hover:bg-gray-700"
                      >
                        {{ item.name }}
                      </router-link>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
          <div class="flex flex-col flex-grow overflow-y-auto px-2">
            <div class="mt-5 flex-grow flex flex-col">
              <nav class="flex-1 px-2" aria-label="Sidebar">
                <div
                  @click="showEmusavirimMenu = !showEmusavirimMenu"
                  :class="[
                    showEmusavirimMenu ? 'bg-indigo-600 rounded-lg' : '',
                  ]"
                >
                  <!-- Current: "bg-gray-100 text-gray-900", Default: "bg-gray-900 text-gray-600 hover:bg-gray-50 hover:text-gray-900" -->
                  <button
                    :class="[
                      showEmusavirimMenu ? 'bg-indigo-600 rounded-sm' : '',
                    ]"
                    type="button"
                    class="bg-gray-800 text-gray-100 group w-full flex items-center pr-2 py-2 text-left text-sm font-medium rounded-md"
                    aria-controls="sub-menu-4"
                    aria-expanded="false"
                  >
                    <!-- Expanded: "text-gray-400 rotate-90", Collapsed: "text-gray-300" -->
                    <svg
                      class="text-gray-300 mr-2 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                    </svg>
                    E-Müşavirim
                  </button>
                  <!-- Expandable link section, show/hide based on state. -->
                  <div
                    v-show="showEmusavirimMenu"
                    class="space-y-1"
                    id="sub-menu-4"
                  >
                    <div class="submenu-content emusavirim" v-if="emusavirim">
                      <router-link
                        v-for="(item, id) in this.$store.state.navigation"
                        v-show="
                          item.permission
                            ? this.checkPermission(item.permission)
                            : true
                        "
                        :key="id"
                        :to="item.to"
                        @click="changeStatus(id, 'emusavirim')"
                        class="block px-3 py-2 rounded-md text-base font-medium text-gray-200 hover:text-white hover:bg-gray-700"
                      >
                        {{ item.name }}
                      </router-link>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
          <div class="flex flex-col flex-grow overflow-y-auto px-2">
            <div class="mt-5 flex-grow flex flex-col">
              <nav class="flex-1 px-2" aria-label="Sidebar">
                <div
                  @click="showEndlessOfficeMenu = !showEndlessOfficeMenu"
                  :class="[
                    showEndlessOfficeMenu ? 'bg-indigo-700 rounded-lg' : '',
                  ]"
                >
                  <!-- Current: "bg-gray-100 text-gray-900", Default: "bg-gray-900 text-gray-600 hover:bg-gray-50 hover:text-gray-900" -->
                  <button
                    type="button"
                    :class="[
                      showEndlessOfficeMenu ? 'bg-indigo-700 rounded-lg' : '',
                    ]"
                    class="bg-gray-800 text-gray-100 group w-full flex items-center pr-2 py-2 text-left text-sm font-medium rounded-md"
                    aria-controls="sub-menu-4"
                    aria-expanded="false"
                  >
                    <!-- Expanded: "text-gray-400 rotate-90", Collapsed: "text-gray-300" -->
                    <svg
                      class="text-gray-300 mr-2 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                    </svg>
                    Endless Office
                  </button>
                  <!-- Expandable link section, show/hide based on state. -->
                  <div
                    v-show="showEndlessOfficeMenu"
                    class="submenu-content office px-4"
                  >
                    <router-link
                      v-for="(item, id) in this.$store.state.endlessOfficeNav"
                      :key="id"
                      :to="item.to"
                      @click="changeStatus(id, 'endlessOffice')"
                      class="block px-3 py-2 rounded-md text-base font-medium text-gray-200 hover:text-white hover:bg-gray-700"
                    >
                      {{ item.name }}
                    </router-link>
                  </div>
                </div>
              </nav>
            </div>
          </div>
          <div class="flex flex-col flex-grow overflow-y-auto px-2">
            <div class="mt-5 flex-grow flex flex-col">
              <nav class="flex-1 px-2" aria-label="Sidebar">
                <div
                  @click="showEnb = !showEnb"
                  :class="[showEnb ? 'bg-blue-300 rounded-lg' : '']"
                >
                  <button
                    :class="[showEnb ? 'bg-blue-300 rounded-lg' : '']"
                    type="button"
                    class="bg-gray-800 text-gray-100 group w-full flex items-center pr-2 py-2 text-left text-sm font-medium rounded-md"
                    aria-controls="sub-menu-4"
                    aria-expanded="false"
                  >
                    <svg
                      class="text-gray-300 mr-2 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                    </svg>
                    ENB
                  </button>
                  <div class="">{{ showEnb }}</div>
                  <div
                    v-show="showEnb"
                    id="sub-menu-1"
                    class="submenu-content enb px-4"
                  >
                    <router-link
                      v-for="(item, id) in this.$store.state.enbNav"
                      :key="id"
                      :to="item.to"
                      @click="changeStatus(id, 'enb')"
                      class="block px-3 py-2 rounded-md text-base font-medium text-gray-200 hover:text-white hover:bg-gray-700"
                    >
                      {{ item.name }}
                    </router-link>
                  </div>
                </div>
              </nav>
            </div>
          </div>
          <div
            @click="logout"
            class="flex flex-col flex-grow overflow-y-auto px-2"
          >
            <div class="mt-2 ml-6 flex-grow flex flex-col">
              <nav class="flex-1 px-2" aria-label="Sidebar">
                <div>
                  <button
                    type="button"
                    class="bg-gray-800 text-gray-100 group w-full flex items-center pr-2 py-2 text-left text-sm font-medium rounded-md"
                    aria-controls="sub-menu-4"
                    aria-expanded="false"
                  >
                    {{ $t("message.logout") }}
                  </button>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </DisclosurePanel>
    <TransitionRoot as="template" :show="addAccountModal">
      <Dialog
        as="div"
        class="fixed z-10 inset-0 overflow-y-auto"
        @close="addAccountModal = false"
      >
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            />
          </TransitionChild>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
            >
              <div>
                <div class="text-center">
                  <div class="">
                    <h2
                      class="mb-5 text-center text-xl font-extrabold text-gray-900"
                    >
                      {{ $t("message.compName") }}
                    </h2>
                    <p class="text-sm text-gray-500">
                      <input
                        v-model="name"
                        type="text"
                        name="city"
                        id="city"
                        class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
              >
                <button
                  type="button"
                  class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                  @click="addAccount"
                >
                  {{ $t("message.save") }}
                </button>
                <button
                  @click="closeModal"
                  class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  ref="cancelButtonRef"
                >
                  {{ $t("message.close") }}
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
    <div
      v-if="subscriptionModal"
      :clickToClose="false"
      class="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
        >
          <div class="text-center">
            <div>
              <div class="flex-grow">
                <div class="grid grid-cols-12 gap-3 flex-grow">
                  <div class="col-span-12 sm:col-span-12">
                    <div
                      class="mt-6 grid grid-cols-3 sm:grid-cols-4 gap-y-6 gap-x-4"
                    >
                      <div class="col-span-3 sm:col-span-4">
                        <label
                          for="name-on-card"
                          class="block text-sm font-medium text-gray-700"
                          >{{ $t("message.cardHolderName") }}</label
                        >
                        <div class="mt-1">
                          <input
                            v-model="card.name"
                            type="text"
                            id="name-on-card"
                            name="name-on-card"
                            autocomplete="off"
                            class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div class="col-span-3 sm:col-span-4">
                        <label
                          class="block text-sm font-medium text-gray-700"
                          >{{ $t("message.cardNumber") }}</label
                        >
                        <div class="mt-1">
                          <credit-card-input
                            v-model="card.cardNumber"
                          ></credit-card-input>
                        </div>
                      </div>

                      <div class="col-span-2 sm:col-span-3">
                        <label
                          class="block text-sm font-medium text-gray-700"
                          >{{ $t("message.cardExpire") }}</label
                        >
                        <div class="mt-1">
                          <card-expiration-date-input
                            v-model="card.expiration"
                          ></card-expiration-date-input>
                        </div>
                      </div>

                      <div>
                        <label
                          class="block text-sm font-medium text-gray-700"
                          >{{ $t("message.CVC") }}</label
                        >
                        <div class="mt-1">
                          <card-cvc-input v-model="card.cvc"></card-cvc-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5">
            <button
              type="button"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
              @click="addPayment"
            >
              Kaydet
            </button>
          </div>
        </div>
      </div>
    </div>
  </Disclosure>
  <Disclosure class="menu-nav-content active" as="div">
    <div class="hidden md:block">
      <div class="submenu-content emusavirim" v-if="emusavirim">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="ml-10 flex items-baseline space-x-4">
            <router-link
              v-for="(item, id) in this.$store.state.navigation"
              :key="id"
              v-show="
                item.permission != ''
                  ? Boolean(this.checkPermission(item.permission))
                  : true
              "
              @click="changeStatus(id, 'emusavirim')"
              :to="item.to"
              :class="[
                item.current
                  ? 'bg-indigo-700 text-white'
                  : 'text-gray-300 menu-btn hover:text-white',
                'px-1 py-2 rounded-md text-sm font-medium',
              ]"
              :aria-current="item.current ? 'page' : undefined"
            >
              {{ item.name }}
            </router-link>
          </div>
        </div>
      </div>
      <div class="submenu-content office" v-if="endlessoffice">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="ml-10 flex items-baseline space-x-4">
            <router-link
              v-for="(item, id) in this.$store.state.endlessOfficeNav"
              :key="id"
              v-show="
                item.permission != ''
                  ? Boolean(this.checkPermission(item.permission))
                  : true
              "
              @click="changeStatus(id, 'endlessOffice')"
              :to="item.to"
              :class="[
                item.current
                  ? 'bg-indigo-700 text-white'
                  : 'text-gray-300 menu-btn hover:text-white',
                'px-3 py-2 rounded-md text-sm font-medium',
              ]"
              :aria-current="item.current ? 'page' : undefined"
              >{{ item.name }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </Disclosure>
  <Disclosure class="menu-nav-content active" as="div">
    <div class="hidden md:block">
      <div class="submenu-content enb" v-if="enb">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="ml-10 flex items-baseline space-x-4">
            <router-link
              v-for="(item, id) in this.$store.state.enbNav"
              :key="id"
              v-show="
                item.permission != ''
                  ? Boolean(this.checkPermission(item.permission))
                  : true
              "
              @click="changeStatus(id, 'enb')"
              :to="item.to"
              :class="[
                item.current
                  ? 'bg-blue-300 text-white'
                  : 'text-gray-300 menu-btn hover:text-white',
                'px-3 py-2 rounded-md text-sm font-medium',
              ]"
              :aria-current="item.current ? 'page' : undefined"
              >{{ item.name }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </Disclosure>
  <Disclosure class="hidden md:block" as="div">
    <div
      v-if="is_email_verified === 0"
      class="bg-yellow-400 mx-auto px-4 sm:px-6 lg:px-8"
    >
      <div class="flex justify-center items-center h-12 space-x-8">
        <span class="text-lg text-yellow-700">{{
          $t("message.emailApproveText")
        }}</span>
        <button
          :disabled="showButton"
          @click="sendVerifyMail"
          class="py-1 px-2 border border-transparent shadow-lg text-base font-medium rounded-md text-white bg-yellow-600"
          :class="[
            !showButton
              ? 'hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
              : '',
          ]"
        >
          {{ $t("message.sendAgain") }}
        </button>
      </div>
    </div>
  </Disclosure>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
  Menu,
  MenuButton,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/vue/outline";
import AuthService from "../services/new/auth";
import AccountsService from "../services/accounts.service";
import { Mixin } from "../library/utils";
import UserService from "../services/user.service";
import moment from "moment";
import CardCvcInput from "../views/Inputs/CardCvcInput";
import CardExpirationDateInput from "../views/Inputs/CardExpirationDateInput";
import CreditCardInput from "../views/Inputs/CreditCardInput";
import PaymentService from "../services/payment.service";

export default {
  mixins: [Mixin],
  components: {
    CardCvcInput,
    CardExpirationDateInput,
    CreditCardInput,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    BellIcon,
    MenuIcon,
    XIcon,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    SelectorIcon,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
  },
  data() {
    return {
      emusavirim: true,
      endlessoffice: false,
      enb: false,
      addAccountModal: false,
      dashboard: false,
      name: "",
      accounts: [],
      selectedItem: {},
      avatar: localStorage.getItem("avatar"),
      user: JSON.parse(localStorage.getItem("user")),
      is_email_verified: false,
      showButton: false,
      showEmusavirimMenu: false,
      showEndlessOfficeMenu: false,
      showEnb: false,
      showProfileMenu: false,
      subscriptionModal: false,
      card: {},
      info: {},
      role_name: localStorage.getItem("role_name"),
      userNavigation: [
        {
          name: "Genel Bilgiler",
          to: `/${this.$store.state.lang}/app/profile`,
          show: true,
        },
        {
          name: "Şirketler",
          to: `/${this.$store.state.lang}/app/profile/companies`,
          show: true,
        },
        {
          name: "Faturalar",
          to: `/${this.$store.state.lang}/app/profile/invoices`,
          show: true,
        },
        {
          name: "Aboneliklerim",
          to: `/${this.$store.state.lang}/app/profile/subscriptions`,
          show: true,
        },
      ],
      showDropdown: false,
      steps: [
        {
          id: "1",
          name: this.$t("message.companySituation"),
          to: `/${this.$store.state.lang}/app/application-form`,
          status: "current",
          visible: false,
          slug: "status",
        },
        {
          id: "2",
          name: this.$t("message.packets"),
          to: `/${this.$store.state.lang}/app/application-form/packets`,
          status: "upcoming",
          visible: true,
          slug: "packets",
        },
        {
          id: "3",
          name: this.$t("message.personalInfo"),
          to: `/${localStorage.getItem(
            "lang"
          )}/app/application-form/personal-info`,
          status: "upcoming",
          visible: true,
          slug: "personal-info",
        },
        {
          id: "4",
          name: this.$t("message.addressInfo"),
          to: `/${localStorage.getItem(
            "lang"
          )}/app/application-form/business-info`,
          status: "upcoming",
          visible: true,
          slug: "business-info",
        },
        {
          id: "5",
          name: this.$t("message.files"),
          to: `/${this.$store.state.lang}/app/application-form/files`,
          status: "upcoming",
          visible: true,
          slug: "files",
        },
        {
          id: "6",
          name: this.$t("message.payment"),
          to: `/${this.$store.state.lang}/app/application-form/payment`,
          status: "upcoming",
          visible: true,
          slug: "payment",
        },
        {
          id: "7",
          name: this.$t("message.approve"),
          to: `/${localStorage.getItem(
            "lang"
          )}/app/application-form/wait-for-approve`,
          status: "upcoming",
          visible: true,
          slug: "wait-for-approve",
        },
      ],
      step: {},
      packetsShow: 0,
      packetsMusavirim: [],
      showAdvisor: false,
      index: -1,
      advisor: { phone: 0, name: "" },
      stepOffice: {},
      stepsOffice: [
        {
          id: "1",
          name: "Lokasyon",
          to: "/app/office/application-form",
          status: "current",
          visible: true,
          slug: "location",
        },
        {
          id: "2",
          name: "Paketler",
          to: "/app/office/application-form/packets",
          status: "upcoming",
          visible: true,
          slug: "office-packets",
        },
        {
          id: "3",
          name: "Ödeme",
          to: "/app/office/application-form/payment",
          status: "upcoming",
          visible: true,
          slug: "office-payment",
        },
        {
          id: "4",
          name: "Onay",
          to: "/app/office/application-form/wait-for-approve",
          status: "upcoming",
          visible: true,
          slug: "office-wait-for-approve",
        },
      ],
      officeIndex: -1,
      officePackets: [],
      secretary: { phone: 0, name: "" },
      packets: [],
      storeAccount: false,
    };
  },
  methods: {
    async getInfo(id) {
      if (
        (id === 0 || id === undefined) &&
        localStorage.getItem("role_name") === "customer"
      ) {
        id =
          localStorage.getItem("account") === "{}"
            ? await this.$router.push({
                name: "Şirket Durumu",
                params: { lang: this.$store.state.lang },
              })
            : JSON.parse(localStorage.getItem("account")).id;
      } else {
        let response = await new AccountsService().getAccount(id);
        if (response.result.informations.length > 0) {
          if (localStorage.getItem("type") === "1") {
            this.step = response.result.informations.find(
              (element) => element.key === "step"
            );
            this.index =
              this.step !== undefined
                ? this.steps.findIndex(
                    (element) => element.slug === this.step.value
                  )
                : -1;
            let products = response.result.products;
            for (let product in products) {
              if (products[product].length > 0) {
                let name = this.$t("message.buyPackageStep");

                this.$store.state.navigation =
                  this.$store.state.navigation.filter(
                    (item) => item.name !== name
                  );
              }
            }
          } else if (localStorage.getItem("type") === "2") {
            this.stepOffice = response.result.informations.find(
              (element) => element.key === "office_step"
            );
            if (this.stepOffice !== undefined) {
              this.officeIndex = this.stepsOffice.findIndex(
                (element) => element.slug === this.stepOffice.value
              );
            } else {
              this.officeIndex = -1;
            }
            let products = response.result.products;
            for (let product in products) {
              if (products[product].length > 0) {
                let name = this.$t("message.buyPackageStep");

                this.$store.state.endlessOfficeNav =
                  this.$store.state.endlessOfficeNav.filter(
                    (item) => item.name !== name
                  );
                if (this.checkPermission("show shipment-tracking") !== true) {
                  this.$store.state.endlessOfficeNav =
                    this.$store.state.endlessOfficeNav.filter(
                      (item) =>
                        item.name !== this.$t("message.shipmentTracking")
                    );
                }
              }
            }
          }
        } else {
          if (localStorage.getItem("type") === "1") {
            await this.$router.push({
              name: "Şirket Durumu",
              params: { lang: this.$store.state.lang },
            });
          } else if (localStorage.getItem("type") === "2") {
            await this.$router.push({
              name: "OfficeLocation",
              params: { lang: this.$store.state.lang },
            });
          }
        }
      }
    },
    async getUserInfo(id) {
      if (
        (id === 0 || id === undefined) &&
        localStorage.getItem("role_name") === "customer"
      ) {
        id =
          localStorage.getItem("account") === "{}"
            ? await this.$router.push({
                name: "Şirket Durumu",
                params: { lang: this.$store.state.lang },
              })
            : JSON.parse(localStorage.getItem("account")).id;
      } else {
        let response = await new AccountsService().getAccount(id);
        let res = await new UserService().getUserInfo(
          JSON.parse(localStorage.getItem("user")).id
        );
        this.is_email_verified = res.result.is_email_verified ? 1 : 0;
        if (localStorage.getItem("type") === "1") {
          this.advisor =
            response.result.users.find(
              (user) => user.roles[0].name === "advisor"
            ) !== undefined
              ? response.result.users.find(
                  (user) => user.roles[0].name === "advisor"
                )
              : { name: "", phone: 0 };
          this.advisor.phone =
            this.advisor.phone !== 0
              ? this.advisor.phone.substring(2, 6) +
                this.advisor.phone.substring(6, 9) +
                this.advisor.phone.substring(9, 11) +
                this.advisor.phone.substring(11, 13)
              : 0;
          this.secretary =
            response.result.users.find(
              (user) => user.roles[0].name === "secretary"
            ) !== undefined
              ? response.result.users.find(
                  (user) => user.roles[0].name === "secretary"
                )
              : { name: "", phone: 0 };
          this.secretary.phone =
            this.secretary.phone !== 0
              ? this.secretary.phone.substring(2, 6) +
                this.secretary.phone.substring(6, 9) +
                this.secretary.phone.substring(9, 11) +
                this.secretary.phone.substring(11, 13)
              : 0;
          if (
            (this.advisor.name === "" || this.packetsMusavirim.length === 0) &&
            this.role_name === "customer"
          ) {
            if (this.index !== -1) {
              this.packetsShow = 0;
              let id = this.index === 6 ? this.index : this.index + 1;
              await this.$router.push(this.steps[id].to);
              this.showAdvisor = false;
            } else {
              await this.$router.push({
                name: "Şirket Durumu",
                params: { lang: this.$store.state.lang },
              });
            }
          } else {
            this.packetsShow = 1;
            this.showAdvisor = true;
          }
        } else if (localStorage.getItem("type") === "2") {
          if (
            (this.secretary.name === "" || this.officePackets.length === 0) &&
            this.role_name === "customer"
          ) {
            if (this.officeIndex !== -1) {
              let id =
                this.officeIndex === 3
                  ? this.officeIndex
                  : this.officeIndex + 1;
              await this.$router.push(this.stepsOffice[id].to);
            } else {
              await this.$router.push({
                name: "OfficeLocation",
                params: { lang: this.$store.state.lang },
              });
            }
          }
        }
      }
    },
    async getUsersProducts(id) {
      if (
        (id === 0 || id === undefined) &&
        localStorage.getItem("role_name") === "customer"
      ) {
        id =
          localStorage.getItem("account") === "{}"
            ? await this.$router.push({
                name: "Şirket Durumu",
                params: { lang: this.$store.state.lang },
              })
            : JSON.parse(localStorage.getItem("account")).id;
      } else {
        let all_products = await new AccountsService().getAccountsProducts(id);
        this.packets = all_products.result.data;
        if (localStorage.getItem("type") === "1") {
          let response = await new AccountsService().getAccountsProducts(
            id,
            "filters=brands:1"
          );
          if (response.result.data.length !== 0) {
            this.packetsMusavirim = response.result.data;
            this.showAdvisor = true;
          }
        } else if (localStorage.getItem("type") === "2") {
          let response = await new AccountsService().getAccountsProducts(
            id,
            "filters=brands:2"
          );
          if (response.result.data.length !== 0) {
            this.officePackets = response.result.data;
            this.showAdvisor = true;
          }
        }
      }
    },
    async getAccounts() {
      let response = await new AccountsService().getAccounts();

      this.accounts = response.result.data;
      if (this.name !== "") {
        this.selectedItem = this.accounts.find(
          (element) => element.name === this.name
        );
        localStorage.setItem("account", JSON.stringify(this.selectedItem));
        localStorage.setItem(
          "company_name",
          JSON.stringify(this.selectedItem.name)
        );
      }
      localStorage.setItem("accounts", JSON.stringify(this.accounts));
    },
    changeStatus(id, type) {
      if (type === "emusavirim") {
        this.$store.state.endlessOfficeNav.forEach(
          (nav) => (nav.current = false)
        );
        this.$store.state.navigation[id].current = true;
        for (let i = 0; i < this.$store.state.navigation.length; i++) {
          if (i !== id) {
            this.$store.state.navigation[i].current = false;
          }
        }
      } else if (type === "endlessOffice") {
        if (id == 3) {
          this.enb = true;
          localStorage.setItem("type", "7");
        } else {
          this.enb = false;
        }
        this.$store.state.endlessOfficeNav[id].current = true;
        this.$store.state.navigation.forEach((nav) => (nav.current = false));
        for (let i = 0; i < this.$store.state.endlessOfficeNav.length; i++) {
          if (i !== id) {
            this.$store.state.endlessOfficeNav[i].current = false;
          }
        }
      } else if (type === "enb") {
        this.$store.state.enbNav[id].current = true;
        this.$store.state.navigation.forEach((nav) => (nav.current = false));
        for (let i = 0; i < this.$store.state.enbNav.length; i++) {
          if (i !== id) {
            this.$store.state.enbNav[i].current = false;
          }
        }
      }
    },
    async addAccount() {
      // if (this.account.name.length <= 60) {

      if (this.name.length <= 60) {
        await new AccountsService().addAccount(this.name);

        this.addAccountModal = false;
        await this.getAccounts();
        await this.$router.push({
          name: "Şirket Durumu",
          params: { lang: this.$store.state.lang },
        });
        this.name = "";
      }
    },
    setAccountId(account) {
      this.$store.state.account = account;
      localStorage.setItem("account", JSON.stringify(account));
      localStorage.setItem("company_name", JSON.stringify(account.name));
      if (
        this.role_name === "advisor" ||
        this.role_name === "secretary" ||
        this.role_name === "admin"
      ) {
        this.$router.push({
          name: "AdvisorDashboard",
          params: { lang: this.$store.state.lang },
        });
      } else {
        this.$router.push({
          name: "Dashboard",
          params: { lang: this.$store.state.lang },
        });
      }
      this.selectedItem = account;
    },
    async sendVerifyMail() {
      await new UserService().sendVerifyMail();
    },
    async refreshToken() {
      let response = await new UserService().refreshToken();
      localStorage.setItem("token", response.result.token);
      let date = new Date(new Date().getTime() + 120 * 60000);
      localStorage.setItem("token_expire", date);
    },
    closeModal() {
      this.selectedItem = JSON.parse(localStorage.getItem("account"));
      this.addAccountModal = false;
    },
    async addPayment() {
      this.card.cardNumber = this.card.cardNumber.replace(/\s/g, "");
      await new PaymentService().addPayment(this.card, this.info.packet_id);
      await this.addInfo();
      await this.setStatus();
    },
    async setProductType(type) {
      if (type === "emusavirim") {
        if (this.role_name === "customer") {
          await this.$router.push({
            name: "Documents",
            params: { lang: this.$store.state.lang },
          });
        }
        this.emusavirim = true;
        this.endlessoffice = false;
        this.dashboard = false;
        this.enb = false;
        localStorage.setItem("type", "1");
        if (localStorage.getItem("account")) {
          await this.getInfo(JSON.parse(localStorage.getItem("account")).id);
          await this.getUsersProducts(
            JSON.parse(localStorage.getItem("account")).id
          );
          await this.getUserInfo(
            JSON.parse(localStorage.getItem("account")).id
          );
        }

        this.$store.state.type = "1";
      } else if (type === "endlessoffice") {
        if (this.role_name !== "customer") {
          let temp = [];
          this.$store.state.endlessOfficeNav.forEach((nav) => {
            if (nav.name !== this.$t("message.additionalServices")) {
              temp.push(nav);
            }
          });
          this.$store.state.endlessOfficeNav = temp;
        }
        this.emusavirim = false;
        this.endlessoffice = true;
        this.dashboard = false;
        localStorage.setItem("type", "2");
        if (localStorage.getItem("account")) {
          await this.getInfo(JSON.parse(localStorage.getItem("account")).id);
          await this.getUsersProducts(
            JSON.parse(localStorage.getItem("account")).id
          );
          await this.getUserInfo(
            JSON.parse(localStorage.getItem("account")).id
          );
        }
        this.$store.state.type = "2";
        await this.$router.push({
          name: "OfficeLocation",
          params: { lang: this.$store.state.lang },
        });
      } else if (type === "dashboard") {
        this.emusavirim = false;
        this.endlessoffice = false;
        this.dashboard = true;
        this.enb = false;
        localStorage.setItem("type", "4");
        this.$store.state.type = "4";
        if (localStorage.getItem("role_name") === "customer") {
          await this.$router.push({
            name: "Dashboard",
            params: { lang: this.$store.state.lang },
          });
        } else {
          if (this.role_name !== "admin") {
            await this.$router.push({
              name: "AdvisorDashboard",
              params: { lang: this.$store.state.lang },
            });
          }
        }
      } else {
        this.emusavirim = false;
        this.endlessoffice = false;
        this.dashboard = false;
        this.enb = true;
        localStorage.setItem("type", "7");
        await this.$router.push({
          name: "EndlessNetworkCompanies",
          params: { lang: this.$store.state.lang },
        });
      }
      if (this.role_name === "customer") {
        this.storeAccount = true;
      }
    },
    async logout() {
      await new AuthService().logout();

      await this.$router.push(`/${this.$store.state.lang}`);
      localStorage.clear();
      localStorage.setItem("lang", this.$i18n.locale);
    },
  },
  computed: {
    reloadAccounts() {
      return this.$store.state.accounts;
    },
    watchRoute() {
      return this.$route;
    },
    reloadAccount() {
      return this.$store.state.account;
    },
    isEmailVerified() {
      return this.$store.state.is_email_verified;
    },
    watchLang() {
      return this.$i18n.locale;
    },
  },
  watch: {
    async reloadAccounts() {
      await this.getAccounts();
      this.accounts = this.$store.state.accounts;
    },
    async watchRoute() {
      let account = JSON.parse(localStorage.getItem("account"));
      if (account.status == 2) {
        this.subscriptionModal = true;
        for (let i = 0; i < account.informations.length; i++) {
          this.info[account.informations[i].key] =
            account.informations[i].value;
        }
      }
      let now = moment(new Date());
      let token_time = moment(new Date(localStorage.getItem("token_expire")));
      if (moment(token_time).isBefore()) {
        const localStorageItems = { ...localStorage };
        for (let k in localStorageItems) {
          if (k !== "lang") {
            localStorage.removeItem(k);
          }
        }
        await this.$router.push({
          name: "Login",
          params: { lang: this.$store.state.lang },
        });
      } else {
        if (token_time.diff(now, "minutes") <= 15) {
          await this.refreshToken();
        }
      }
      if (this.$route.path.includes("app/profile")) {
        this.emusavirim = false;
        this.endlessoffice = false;
        this.dashboard = false;
        this.enb = false;
      }
    },
    async reloadAccount() {
      this.selectedItem = this.$store.state.account;
      await this.getAccounts();
    },
    async isEmailVerified() {
      this.is_email_verified = this.$store.state.is_email_verified ? 1 : 0;
    },
    watchLang(val) {
      localStorage.setItem("lang", val);
      this.$store.state.lang = val;
      this.$router.go(this.$route.name, { lang: val });
    },
  },
  async created() {
    if (JSON.parse(localStorage.getItem("account"))) {
      this.selectedItem = JSON.parse(localStorage.getItem("account"));
    } else if (this.role_name === "customer") {
      this.addAccountModal = true;
    }
    let findPathIndex = this.$store.state.navigation.findIndex(
      (element) => element.to === this.$route.path
    );
    if (findPathIndex !== -1) {
      this.$store.state.navigation[findPathIndex].current = true;
    }
    this.userNavigation[3].show = this.role_name === "customer";
    if (localStorage.getItem("type") === "1") {
      await this.setProductType("emusavirim");
    } else if (localStorage.getItem("type") === "2") {
      await this.setProductType("endlessoffice");
    } else if (localStorage.getItem("type") === "4") {
      await this.setProductType("dashboard");
    } else {
      await this.setProductType("enb");
    }
  },
};
</script>
<style scoped lang="scss">
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .app-container {
    max-width: 441px;
  }
}

.firm-select {
  min-width: 160px;
  margin-right: 15px;
}

#headlessui-menu-items-7 {
  z-index: 999999999999 !important;
}

.pr-20 {
  padding-right: 20px;
}

.company-drop-list {
  border-radius: 7px;
  margin-top: 9px;
  box-shadow: 1px 7px 12px #787878ad;
}

.new-company-btn {
  border-radius: 0px 0px 7px 7px;
  display: flex;
  border-radius: 0px 0px 7px 7px;
  height: 36px;
  align-items: center;

  img {
    margin-right: 5px !important;
    width: 15px !important;
  }

  span {
    margin-right: 10px;
  }
}

.bg-gray {
  background: #ededed;
}

.info-button {
  &:focus {
    border: 0px !important;
  }
}

.logout-btn {
  width: 100%;
  text-align: left;
  z-index: 999999;

  &:hover {
    background: #f5f5f5;
  }
}

.display-none {
  display: none;
}

.nav-step-btn {
  display: flex;
  height: 64px;
  padding: 0px 10px;
  align-items: flex-end;
  color: white;

  .menu-btn {
    height: 80%;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    border-radius: 5px 5px 0px 0px;

    &:hover {
      background: #111826;
      cursor: pointer;
    }
  }

  .emusavirim {
    &.active {
      background: #4338ca;
      color: white;
    }
  }

  .office {
    &.active {
      background: #4f65c6;
      color: white;
    }
  }

  .enb {
    &.active {
      background: #149eec;
      color: white;
    }
  }
}

.dashboard {
  &.active {
    background: #796dfc;
    color: white;
    //border-radius: 5px;
  }
}

.menu-nav-content {
  .submenu-content {
    padding: 10px;

    &.emusavirim {
      background: #4338ca;
      color: white;

      a {
        color: white !important;

        &:hover {
          background: #4338ca !important;
        }
      }

      &.emusavirim.active.router-link-active {
        background: #4338ca !important;
      }
    }

    &.office {
      background: #4f65c6;
      color: white;

      a {
        color: white !important;

        &:hover {
          background: #3950b9 !important;
        }
      }

      .router-link-active {
        background: #3950b9 !important;
      }
    }

    &.enb {
      background: #149eec;

      a {
        color: white !important;

        &:hover {
          background: #308dd3 !important;
        }
      }

      .router-link-active {
        background: #308dd3 !important;
      }
    }

    &.dashboard {
      background: #796dfc;
      color: white;

      a {
        color: white !important;

        &:hover {
          background: #796dfc !important;
        }
      }

      .router-link-active {
        background: #796dfc !important;
      }
    }
  }
}
</style>
