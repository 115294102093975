<template>
  <div class="relative bg-gray-50">
    <div class="absolute inset-0">
      <div class="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
    </div>
    <div class="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
      <div
        class="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12"
      >
        <div class="max-w-lg mx-auto">
          <h2
            class="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl"
          >
            {{ $t("message.contactUs") }}
          </h2>
          <p class="mt-3 text-lg leading-6 text-gray-500">
            {{ $t("message.leaveAMessage") }}
          </p>
          <dl class="mt-8 text-base text-gray-500">
            <div>
              <dd>
                <p>
                  Cemal Sahir Sok. No: 26-28 Profilo Plaza A Blok K:1
                  Mecidiyeköy 34394
                </p>
                <p>Şişli / İstanbul</p>
              </dd>
            </div>
            <div class="mt-6">
              <dt class="sr-only">Phone number</dt>
              <dd class="flex">
                <PhoneIcon
                  class="flex-shrink-0 h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
                <span class="ml-3"> +90(212) 949 0 363 </span>
              </dd>
            </div>
            <div class="mt-3">
              <dt class="sr-only">Email</dt>
              <dd class="flex">
                <MailIcon
                  class="flex-shrink-0 h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
                <span class="ml-3"> info@emusavirim.net </span>
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div
        class="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12"
      >
        <div class="max-w-lg mx-auto lg:max-w-none">
          <form class="grid grid-cols-1 gap-y-6">
            <div>
              <label for="full-name" class="sr-only"
                >{{ $t("message.name") }} {{ $t("message.surname") }}</label
              >
              <input
                v-model="contact.full_name"
                type="text"
                name="full-name"
                id="full-name"
                autocomplete="off"
                class="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                placeholder="Ad Soyad"
              />
            </div>
            <div>
              <label for="email" class="sr-only">E-Posta</label>
              <input
                v-model="contact.email"
                id="email"
                name="email"
                type="email"
                autocomplete="off"
                class="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                placeholder="Email"
              />
            </div>
            <div>
              <label for="phone" class="sr-only">{{
                $t("message.phone")
              }}</label>
              <input
                v-model="contact.phone"
                v-maska="'### ### ## ##'"
                id="phone"
                name="email"
                type="text"
                autocomplete="off"
                class="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                placeholder="Telefon"
              />
            </div>
            <div>
              <label for="message" class="sr-only">{{
                $t("message.yourMessage")
              }}</label>
              <textarea
                v-model="contact.message"
                id="message"
                name="message"
                rows="4"
                class="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                placeholder="Mesajınız"
              />
            </div>
            <div>
              <button
                @click="sendMail"
                class="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {{ $t("message.send") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <input class="hidden" name="honeypot_time" :value="honeypot_time" />
    <input class="hidden" name="honeypot" value="" />
  </div>
</template>

<script>
import { MailIcon, PhoneIcon } from "@heroicons/vue/outline";
import ContactService from "../../services/contact.service";
import { Mixin } from "../../library/utils";
import { maska } from "maska";

export default {
  components: {
    MailIcon,
    PhoneIcon,
  },
  mixins: [Mixin],
  directives: {
    maska,
  },
  data() {
    return {
      contact: { message: "", phone: "", email: "", full_name: "" },
      honeypot_time: "",
    };
  },
  methods: {
    async sendMail(e) {
      e.preventDefault();
      this.contact.phone = this.contact.phone.replace(/\s/g, "");
      var patt = new RegExp("(05|5)[0-9][0-9][0-9]([0-9]){6}");
      var res = patt.test(this.contact.phone);
      if (this.checkData(this.contact) && res) {
        await new ContactService().sendMail(this.contact, this.honeypot_time);
        this.contact = {};
      }
    },
    async honeyPot() {
      let response = await new ContactService().honeyPot();
      this.honeypot_time = response.result;
    },
  },
  mounted() {
    let script = document.createElement("script");
    script.async = true;
    script.src = "https://endigitals.com/utm.js";
    document.head.appendChild(script);
  },

  async created() {
    await this.honeyPot();
  },
};
</script>
