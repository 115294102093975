import { createI18n } from "vue-i18n/index";
import tr from "../locales/tr.json";
import en from "../locales/en.json";

const messages = {
  tr: {
    message: tr,
  },
  en: {
    message: en,
  },
};
const i18n = createI18n({
  locale: localStorage.getItem("lang") ? localStorage.getItem("lang") : "tr", // set locale
  fallbackLocale: "tr", // set fallback locale
  messages, // set locale messages
});
export default i18n;
