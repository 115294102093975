import axios from "axios";
import authHeader from "./auth-header";

class AccountsService {
  addAccount(name) {
    return axios
      .post(
        "accounts",
        {
          name: name,
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }
  getAccounts() {
    return axios
      .get(`accounts`, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
  getAccount(accountID) {
    return axios
      .get(`accounts/${accountID}`, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
  getAccountAll(accountID, all) {
    return axios
      .get(`accounts/${accountID}${all ? `?all` : ""}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
  // getAccount(id, user_id) {
  //   axios
  //     .get(
  //       `accounts${id ? `/${id}` : ""}`,
  //       user_id
  //         ? {
  //             headers: authHeader(),
  //             params: { user_id: user_id },
  //           }
  //         : { headers: authHeader() }
  //     )
  //     .then((response) => {
  //       return response;
  //     })
  //     .catch((error) => {
  //       return error;
  //     });
  // }

  editAccount(user_id, id, bodyParams) {
    return axios
      .put(
        `accounts/${id}`,
        bodyParams,
        user_id
          ? {
              headers: authHeader(),
              params: { user_id: user_id },
            }
          : { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  deleteAccount(user_id, id) {
    return axios
      .delete(
        `accounts/${id}`,
        user_id
          ? {
              headers: authHeader(),
              params: { user_id: user_id },
            }
          : { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  addInfo(information, id, step_name, user_id) {
    return axios
      .post(
        `accounts/${id}/informations`,
        {
          informations: [
            {
              key: "identityNo",
              value: information.identityNo,
            },
            {
              key: "birthCity",
              value: information.birthCity,
            },
            {
              key: "birthDist",
              value: information.birthDist,
            },
            {
              key: "birthDate",
              value: information.birthDate,
            },
            {
              key: "city",
              value: information.city,
            },
            {
              key: "district",
              value: information.district,
            },
            {
              key: "phone",
              value: information.phone,
            },
            {
              key: "street",
              value: information.street,
            },
            {
              key: "neighbourhood",
              value: information.neighbourhood,
            },
            {
              key: "buildingNo",
              value: information.buildingNo,
            },
            {
              key: "doorNo",
              value: information.doorNo,
            },
            {
              key: "step",
              value: step_name,
            },
          ],
        },
        user_id
          ? {
              headers: authHeader(),
              params: { user_id: user_id },
            }
          : { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  getAccountsProducts(id, filter, all) {
    return axios
      .get(
        `accounts/${id}/products${all ? `?${all}` : ""}${
          filter ? `?${filter}` : ""
        }`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  setPacketTypeInfo(packet, id, step_name) {
    return axios
      .post(
        `accounts/${id}/informations`,
        {
          informations: [
            {
              key: "packet_type",
              value: packet,
            },
            {
              key: "step",
              value: step_name,
            },
          ],
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  getAllAccount() {
    return axios
      .get(`accounts/?all`, { headers: authHeader() })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  addTaxInfo(id, info) {
    return axios
      .post(
        `accounts/${id}/informations`,
        {
          informations: [
            {
              key: "tax_office",
              value: info.office,
            },
            {
              key: "tax_number",
              value: info.number,
            },
          ],
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }
  setChangeStatus(account, status) {
    return axios
      .put(
        `accounts/${account}/change-status?all=1`,
        { status: status },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }
}

export default AccountsService;
