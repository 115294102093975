<template>
  <div class="app-container">
    <navbar v-if="showNavbar" />
    <router-view />
    <loading></loading>
    <notifications></notifications>
  </div>
</template>

<script>
import Loading from "./views/Loading";
import Notifications from "./views/Notifications";
import Navbar from "./components/Navbar";

export default {
  components: { Navbar, Notifications, Loading },
  data() {
    return {
      showNavbar: false,
    };
  },
  computed: {
    currentRoute() {
      return this.$route.name;
    },
  },
  watch: {
    currentRoute(val) {
      this.showNavbar = false;
      const withoutNavRoutes = [
        "Login",
        "Register",
        "Landing",
        "Forgot-pass",
        "Change-pass",
        "VerifyEmail",
        "VerifyPhoneNumber",
        "Politikalar",
        "Privacy Policy",
        "Cookie Policy",
        "User Agreement",
      ];
      if (!withoutNavRoutes.includes(val)) {
        this.showNavbar = true;
      }
    },
  },
  async created() {
    let lang = this.$i18n.locale ? this.$i18n.locale : "tr";
    await this.$store.commit("setLang", lang);
    await this.$store.dispatch("getNavs");
    localStorage.setItem("lang", lang);

    setInterval(() => {
      if (
        this.$store.state.user &&
        new Date().getTime() - this.$store.state.lastActiveTime > 7200000
      ) {
        localStorage.clear();
      }
    }, 1000);
  },
};
</script>
<style scoped>
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .app-container {
    max-width: 100%;
  }
}
#headlessui-menu-items-7 {
  z-index: 999999999999 !important;
}
</style>
