<template>
  <div>
    <div class="mt-1 relative rounded-md shadow-sm">
      <input
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        type="text"
        v-on:keypress="cardNumberValidations($event)"
        v-maska="'#### #### #### ####'"
      />
      <div v-if="(cardNumber.length > 1) & (cardNumber.length < 19)">
        <div
          class="mb-7 absolute inset-y-0 right-0 pr-2 flex items-center pointer-events-none"
        >
          <ExclamationCircleIcon
            class="h-5 w-5 text-red-500"
            aria-hidden="true"
          />
        </div>
        <p class="mt-2 text-sm text-red-600">
          Kart Numarası 16 Haneden Küçük Olamaz
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ExclamationCircleIcon } from "@heroicons/vue/solid";
import { maska } from "maska";
export default {
  name: "CreditCardInput",
  components: {
    ExclamationCircleIcon,
  },
  props: {
    modelValue: String,
  },
  directives: {
    maska,
  },
  data() {
    return {
      cardNumber: "",
    };
  },
  methods: {
    cardNumberValidations(e) {
      if (!e.key.match(/^\d+$/)) {
        e.preventDefault();
      }
    },
  },
};
</script>

<style scoped></style>
