import { createRouter, createWebHistory } from "vue-router";
import Landing from "../views/Landing.vue";
import AccountsService from "../services/accounts.service";
import moment from "moment";
import store from "@/store";
const routes = [
  {
    path: "/",
    redirect: { path: "/tr" },
    meta: {
      title: "landing",
    },
  },
  {
    path: "/:lang",
    name: "Landing",
    component: Landing,
    meta: {
      title: "landing",
    },
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: () =>
      import(/* webpackChunkName: "privacyPolicy" */ "../views/PrivacyPolicy"),
    meta: {
      title: "privacyPolicy",
    },
  },
  {
    path: "/cookie-policy",
    name: "Cookie Policy",
    component: () =>
      import(/* webpackChunkName: "privacyPolicy" */ "../views/CookiePolicy"),
    meta: {
      title: "cookiePolicy",
    },
  },
  {
    path: "/user-agreement",
    name: "User Agreement",
    component: () =>
      import(/* webpackChunkName: "privacyPolicy" */ "../views/UserAgreement"),
    meta: {
      title: "userAgreement",
    },
  },
  {
    path: "/:lang/app",
    name: "App",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Application"),
    meta: {
      title: "landing",
    },
    children: [
      {
        path: "",
        name: "Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Application/Dashboard.vue"
          ),
        meta: {
          title: "home",
          requiresAuth: true,
        },
      },
      {
        path: "dashboard",
        name: "AdvisorDashboard",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Advisor/AdvisorDashboard"
          ),
        meta: {
          title: "home",
          requiresAuth: true,
        },
      },
      {
        path: "appointment-list",
        name: "AppointmentList",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Advisor/AppointmentList.vue"
          ),
        meta: {
          title: "appointmentList",
          requiresAuth: true,
        },
      },
      {
        path: "login",
        name: "Login",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Application/Login.vue"
          ),
        meta: {
          title: "login",
          requiresUnauth: true,
        },
      },
      {
        path: "email/verify/:id/:hash",
        name: "VerifyEmail",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Application/VerifyEmail"
          ),
        meta: {
          title: "verify",
        },
      },
      {
        path: "phone-number/verify",
        name: "VerifyPhoneNumber",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Application/VerifyPhoneNumber"
          ),
        meta: {
          title: "verify",
        },
      },
      {
        path: "register",
        name: "Register",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Application/Register.vue"
          ),
        meta: {
          title: "register",
          requiresUnauth: true,
        },
      },
      {
        path: "forgot-pass",
        name: "Forgot-pass",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Application/Forgot-pass.vue"
          ),
        meta: {
          title: "forgotPassword",
          requiresUnauth: true,
        },
      },
      {
        path: "change-pass",
        name: "Change-pass",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Application/Change-pass.vue"
          ),
        meta: {
          title: "changePassword",
          requiresUnauth: true,
        },
      },
      {
        path: "documents",
        name: "Documents",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Application/Documents.vue"
          ),
        meta: {
          title: "" + "docs",
          requiresAuth: true,
        },
      },
      {
        path: "packets/:id",
        name: "Packets",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Application/ApplicationForm/Packets.vue"
          ),
        meta: {
          title: "packets",
          requiresAuth: true,
        },
      },
      {
        path: "accountancy",
        name: "Accountancy",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Application/Accountancy.vue"
          ),
        meta: {
          title: "accountant",
          requiresAuth: true,
        },
      },
      {
        path: "accounts",
        name: "Accounts",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Application/Accounts"
          ),
        meta: {
          title: "companies",
          requiresAuth: true,
        },
      },
      {
        path: "users",
        name: "Users",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Application/Users"),
        meta: {
          title: "users",
          requiresAuth: true,
        },
      },
      {
        path: "advisors",
        name: "Advisors",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Application/Advisors"
          ),
        meta: {
          title: "advisors",
          requiresAuth: true,
        },
      },
      {
        path: "secretaries",
        name: "Secretaries",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Application/Secretaries"
          ),
        meta: {
          title: "secretaries",
          requiresAuth: true,
        },
      },
      {
        path: "additional-services",
        name: "AdditionalServices",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Application/AdditionalServices/AdditionalServices.vue"
          ),
        meta: {
          title: "additionalServices",
          requiresAuth: true,
        },
      },
      {
        path: "office/additional-services",
        name: "OfficeAdditionalServices",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/EndlessOffice/OfficeAdditionalServices"
          ),
        meta: {
          title: "additionalServices",
          requiresAuth: true,
        },
      },
      {
        path: "office/additional-payment",
        name: "OfficeAdditionalPayment",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/EndlessOffice/OfficeAdditionalPayment"
          ),
        meta: {
          title: "additionalServices",
          requiresAuth: true,
        },
      },
      {
        path: "office/calls",
        name: "OfficeCalls",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/EndlessOffice/CallInformation"
          ),
        meta: {
          title: "callInfo",
          requiresAuth: true,
        },
      },
      {
        path: "office/shipment-tracking",
        name: "OfficeShipments",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/EndlessOffice/ShipmentInformation"
          ),
        meta: {
          title: "shipmentTracking",
          requiresAuth: true,
        },
      },
      {
        path: "announcements",
        name: "announcements",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Application/Announcements"
          ),
        meta: {
          title: "announcement",
          requiresAuth: true,
        },
      },
      {
        path: "additional-payment",
        name: "AdditionalPayment",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Application/AdditionalServices/AdditionalPayment.vue"
          ),
        meta: {
          title: "additionalServices",
          requiresAuth: true,
        },
      },
      {
        path: "profile",
        name: "Profile",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Application/Profile/Profile"
          ),
        children: [
          {
            path: "",
            name: "GeneralInfo",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/Application/Profile/GeneralInfo.vue"
              ),
            meta: {
              title: "generalInfo",
              requiresAuth: true,
            },
          },
          {
            path: "change-password",
            name: "ChangePassword",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/Application/Profile/ChangePassword.vue"
              ),
            meta: {
              title: "changePassword",
              requiresAuth: true,
            },
          },
          {
            path: "companies",
            name: "companies",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/Application/Profile/Companies.vue"
              ),
            meta: {
              title: "myCompanies",
              requiresAuth: true,
            },
          },
          {
            path: "invoices",
            name: "invoices",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/Application/Profile/Invoices.vue"
              ),
            meta: {
              title: "myInvoices",
              requiresAuth: true,
            },
          },
          {
            path: "company-edit/:id",
            name: "CompanyEdit",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/Application/Profile/CompanyEditPage"
              ),
            meta: {
              title: "myCompany",
              requiresAuth: true,
            },
          },
          {
            path: "subscriptions",
            name: "Subscriptions",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/Application/Profile/Subscriptions"
              ),
            meta: {
              title: "subscriptions",
              requiresAuth: true,
            },
          },
          {
            path: "team-member",
            name: "TeamMember",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/Application/Profile/TeamMemberList.vue"
              ),
            meta: {
              title: "employees",
              requiresAuth: true,
            },
          },
          {
            path: "add-team-member",
            name: "AddTeamMember",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/Application/Profile/AddTeamMember.vue"
              ),
            meta: {
              title: "addEmployee",
              requiresAuth: true,
            },
          },
          {
            path: "edit-team-member/:id",
            name: "EditTeamMember",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/Application/Profile/EditTeamMember.vue"
              ),
            meta: {
              title: "editEmployee",
              requiresAuth: true,
            },
          },
        ],
        meta: {
          title: "profile",
          requiresAuth: true,
        },
      },
      {
        path: "roles-and-permissions",
        name: "RoleAndPermissions",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Application/RoleAndPermissions.vue"
          ),
        meta: {
          title: "rolesAndPermissions",
          requiresAuth: true,
        },
      },
      {
        path: "roles",
        name: "Roles",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Application/Roles.vue"
          ),
        meta: {
          title: "roles",
          requiresAuth: true,
        },
      },
      {
        path: "accounts-detail/:id",
        name: "AccountsDetail",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Application/AccountsDetail.vue"
          ),
        meta: {
          title: "AccountsDetail",
          requiresAuth: true,
        },
      },
      {
        path: "application-form",
        name: "ApplicationForm",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Application/ApplicationForm"
          ),
        children: [
          {
            path: "",
            name: "Şirket Durumu",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/Application/Home.vue"
              ),
            meta: {
              title: "companySituation",
              requiresAuth: true,
            },
          },
          {
            path: "packets",
            name: "Packets",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/Application/ApplicationForm/Packets.vue"
              ),
            meta: {
              title: "packets",
              requiresAuth: true,
            },
          },
          {
            path: "personal-info",
            name: "Personelinfo",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/Application/ApplicationForm/PersonelInfo.vue"
              ),
            meta: {
              title: "personalInfo",
              requiresAuth: true,
            },
          },
          {
            path: "business-info",
            name: "BusinessInfo",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/Application/ApplicationForm/BusinessInfo.vue"
              ),
            meta: {
              title: "activityArea",
              requiresAuth: true,
            },
          },
          {
            path: "files",
            name: "Files",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/Application/ApplicationForm/Files.vue"
              ),
            meta: {
              title: "files",
              requiresAuth: true,
            },
          },
          // {
          //   path: "payment",
          //   name: "Payment",
          //   component: () =>
          //       import(/* webpackChunkName: "about" */ "../views/Application/ApplicationForm/AdditionalPayment.vue"),
          // }
          {
            path: "thanks",
            name: "Thanks",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/Application/ApplicationForm/Thanks.vue"
              ),
            meta: {
              title: "thanks",
              requiresAuth: true,
            },
          },
          {
            path: "payment",
            name: "Payment",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/Application/ApplicationForm/Payment.vue"
              ),
            meta: {
              title: "payment",
              requiresAuth: true,
            },
          },
          {
            path: "wait-for-approve",
            name: "WaitForApprove",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/Application/ApplicationForm/WaitForApprove.vue"
              ),
            meta: {
              title: "awaitingApproval",
              requiresAuth: true,
            },
          },
          {
            path: "company-founded",
            name: "company-founded",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/Application/ApplicationForm/CompanyFounded.vue"
              ),
            meta: {
              title: "companyFounded",
              requiresAuth: true,
            },
          },
        ],
        meta: {
          title: "bundlePurchase",
          requiresAuth: true,
        },
      },
      {
        path: "office/application-form",
        name: "OfficeApplicationForm",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/EndlessOffice/Steps/Index"
          ),
        children: [
          {
            path: "",
            name: "OfficeLocation",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/EndlessOffice/Steps/Locations"
              ),
            meta: {
              title: "officeLocation",
              requiresAuth: true,
            },
          },
          {
            path: "packets",
            name: "OfficePackets",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/EndlessOffice/Steps/Packets"
              ),
            meta: {
              title: "officePackets",
              requiresAuth: true,
            },
          },
          {
            path: "payment",
            name: "OfficePayment",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/EndlessOffice/Steps/Payment"
              ),
            meta: {
              title: "payment",
              requiresAuth: true,
            },
          },
          {
            path: "wait-for-approve",
            name: "OfficeWaitForApprove",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/EndlessOffice/Steps/WaitForApprove"
              ),
            meta: {
              title: "awaitingApproval",
              requiresAuth: true,
            },
          },
        ],
        meta: {
          title: "bundlePurchase",
          requiresAuth: true,
        },
      },
      {
        path: "endless-network/companies",
        name: "EndlessNetworkCompanies",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/EndlessNetwork/Companies.vue"
          ),
        meta: {
          title: "companies",
          requiresAuth: true,
        },
      },
      {
        path: "endless-network/stage/:id",
        name: "EndlessNetworkStage",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/EndlessNetwork/Stage.vue"
          ),
        meta: {
          title: "stage",
          requiresAuth: true,
        },
      },
      {
        path: "endless-network/additional-service",
        name: "EndlessNetworkAdditionalService",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/EndlessNetwork/AdditionalService.vue"
          ),
        meta: {
          title: "additionalServices",
          requiresAuth: true,
        },
      },
      {
        path: "endless-network/stand/:id",
        name: "EndlessNetworkStand",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/EndlessNetwork/Stand.vue"
          ),
        meta: {
          title: "stand",
          requiresAuth: true,
        },
      },
      {
        path: "endless-network/activity-calendar",
        name: "EndlessNetworkActivityCalendar",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/EndlessNetwork/ActivityCalendar.vue"
          ),
        meta: {
          title: "activityCalendar",
          requiresAuth: true,
        },
      },
      {
        path: "endless-network/for-my-company",
        name: "EndlessNetworkForMyCompany",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/EndlessNetwork/EnbForMyCompany.vue"
          ),
        meta: {
          title: "endlessNetwork",
          requiresAuth: true,
        },
      },
      {
        path: "endless-network/additional-payment",
        name: "EndlessNetworkForAdditionalPayment",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/EndlessNetwork/AdditionalPayment.vue"
          ),
        meta: {
          title: "payment",
          requiresAuth: true,
        },
      },
      {
        path: "endless-network/my-company-stage",
        name: "EndlessNetworkMyCompanyStage",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/EndlessNetwork/EnbMyCompanyStage.vue"
          ),
        meta: {
          title: "endlessNetwork",
          requiresAuth: true,
        },
      },
      {
        path: "endless-network/stage-payment",
        name: "EndlessNetworkStagePayment",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/EndlessNetwork/StagePayment.vue"
          ),
        meta: {
          title: "endlessNetwork",
          requiresAuth: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  document.title = store.state.metaTitles[to.meta.title];
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresUnauth = to.matched.some(
    (record) => record.meta.requiresUnauth
  );
  let lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : "tr";
  let steps = [
    {
      to: `/${lang}/app/application-form`,
      slug: "status",
    },
    {
      to: `/${lang}/app/application-form/packets`,
      slug: "packets",
    },
    {
      to: `/${lang}/app/application-form/personal-info`,
      slug: "personal-info",
    },
    {
      to: `/${lang}/app/application-form/business-info`,
      slug: "business-info",
    },
    {
      to: `/${lang}/app/application-form/files`,
      slug: "files",
    },
    {
      to: `/${lang}/app/application-form/payment`,
      slug: "payment",
    },
    {
      to: `/${lang}/app/application-form/wait-for-approve`,
      slug: "wait-for-approve",
    },
  ];

  let user = localStorage.getItem("token");
  let role_name = localStorage.getItem("role_name");
  if (!!user && requiresUnauth) {
    let account =
      localStorage.getItem("account") !== "{}"
        ? JSON.parse(localStorage.getItem("account"))
        : localStorage.clear();

    if (account == null || account.length == 0) {
      localStorage.clear();
    }
    let products = await new AccountsService().getAccountsProducts(account.id);
    let token_time = moment(new Date(localStorage.getItem("token_expire")));
    if (moment(token_time).isBefore() || products.response === 401) {
      const localStorageItems = { ...localStorage };
      for (let k in localStorageItems) {
        if (k !== "lang") {
          localStorage.removeItem(k);
        }
      }

      next(`/${lang}/app/login`);
    }
    if (products.result.data.length > 0 && role_name === "customer") {
      next(`/${lang}/app`);
    } else if (
      account.informations &&
      account.informations.length !== 0 &&
      role_name === "customer"
    ) {
      let step = account.informations.find((element) => element.key === "step");
      if (step === undefined) {
        next(steps[0].to);
      } else {
        let index = steps.findIndex((element) => element.slug === step.value);
        if (step.value !== "wait-for-approve") {
          next(steps[index + 1].to);
        } else {
          next(steps[index].to);
        }
      }
    } else if (
      role_name === "advisor" ||
      role_name === "secretary" ||
      role_name === "admmin"
    ) {
      next(`/${lang}/app/dashboard`);
    } else {
      next(`/${lang}/app/application-form`);
    }
  } else if (!user && requiresAuth) {
    next(`/${lang}/app/login`);
  } else {
    next();
  }
});

export default router;
