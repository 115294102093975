export const Mixin = {
  methods: {
    checkPermission(method) {
      const permissions = JSON.parse(localStorage.getItem("permissions"));

      if (permissions) {
        const index = permissions.filter(
          (permission) => permission.name === method
        );
        return !!index[0];
      }
      return false;
    },
    checkData(data) {
      for (const key in data) {
        if (data[key] === "" || data[key] === undefined) {
          this.$store.dispatch("showNotification", {
            title: "Hata",
            description: "Lütfen tüm alanları doldurduğunuzdan emin olun",
            type: "error",
          });
          return false;
        }
      }
      return true;
    },
  },
};
