<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="py-12 bg-white">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="lg:text-center">
        <p
          class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl"
        >
          {{ $t("message.howIsWork") }}
        </p>
        <p class="mt-3 max-w-2xl text-xl text-gray-500 lg:mx-auto">
          {{ $t("message.howIsWorkText") }}
        </p>
      </div>

      <div class="py-12 bg-white">
        <div class="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <h2 class="sr-only">A better way to send money.</h2>
          <dl class="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
            <div class="flex justify-center text-center">
              <div>
                <dt>
                  <div class="flex items-center justify-center">
                    <div
                      class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white"
                    >
                      <LoginIcon class="h-6 w-6" />
                    </div>
                  </div>
                  <p class="mt-5 text-lg leading-6 font-medium text-gray-900">
                    {{ $t("message.registerNowChoosePacket") }}
                  </p>
                </dt>
                <dd class="flex justify-center mt-2 text-base text-gray-500">
                  {{ $t("message.registerNowChoosePacketDesc") }}
                </dd>
              </div>
            </div>
            <div class="flex justify-center text-center">
              <div>
                <dt>
                  <div class="flex items-center justify-center">
                    <div
                      class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white"
                    >
                      <BriefcaseIcon class="h-6 w-6" />
                    </div>
                  </div>
                  <p class="mt-5 text-lg leading-6 font-medium text-gray-900">
                    {{ $t("message.moveCompOrCreateComp") }}
                  </p>
                </dt>
                <dd class="flex justify-center mt-2 text-base text-gray-500">
                  {{ $t("message.moveCompOrCreateCompDesc") }}
                </dd>
              </div>
            </div>
            <div class="flex justify-center text-center">
              <div>
                <dt>
                  <div class="flex items-center justify-center">
                    <div
                      class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white"
                    >
                      <OfficeBuildingIcon class="h-6 w-6" />
                    </div>
                  </div>
                  <p class="mt-5 text-lg leading-6 font-medium text-gray-900">
                    {{ this.$t("message.chooseOffice") }}
                  </p>
                </dt>
                <dd class="flex justify-center mt-2 text-base text-gray-500">
                  {{ $t("message.chooseOfficeDesc") }}
                </dd>
              </div>
            </div>
            <div class="flex justify-center text-center">
              <div>
                <dt>
                  <div class="flex items-center justify-center">
                    <div
                      class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white"
                    >
                      <DesktopComputerIcon class="h-6 w-6" />
                    </div>
                  </div>
                  <p class="mt-5 text-lg leading-6 font-medium text-gray-900">
                    {{ $t("message.digitalFinance") }}
                  </p>
                </dt>
                <dd class="flex justify-center mt-2 text-base text-gray-500">
                  {{ $t("message.digitalFinanceDesc") }}
                </dd>
              </div>
            </div>
            <div class="flex justify-center text-center">
              <div>
                <dt>
                  <div class="flex items-center justify-center">
                    <div
                      class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white"
                    >
                      <CalculatorIcon class="h-6 w-6" />
                    </div>
                  </div>
                  <p class="mt-5 text-lg leading-6 font-medium text-gray-900">
                    {{ $t("message.bookkeeping") }}
                  </p>
                </dt>
                <dd class="flex justify-center mt-2 text-base text-gray-500">
                  {{ $t("message.bookkeepingDesc") }}
                </dd>
              </div>
            </div>
            <div class="flex justify-center text-center">
              <div>
                <dt>
                  <div class="flex items-center justify-center">
                    <div
                      class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white"
                    >
                      <DocumentAddIcon class="h-6 w-6" />
                    </div>
                  </div>
                  <p class="mt-5 text-lg leading-6 font-medium text-gray-900">
                    {{ $t("message.additionalService") }}
                  </p>
                </dt>
                <dd class="flex justify-center mt-2 text-base text-gray-500">
                  {{ $t("message.additionalServiceDesc") }}
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  LoginIcon,
  OfficeBuildingIcon,
  BriefcaseIcon,
  CalculatorIcon,
  DocumentAddIcon,
  DesktopComputerIcon,
} from "@heroicons/vue/outline";

export default {
  components: {
    LoginIcon,
    OfficeBuildingIcon,
    BriefcaseIcon,
    CalculatorIcon,
    DocumentAddIcon,
    DesktopComputerIcon,
  },
};
</script>
